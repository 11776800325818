.sorter {
  margin-block-end: 20px;

  .sorter__counter {
    color: clr(anthracite);
  }

  @include media-breakpoint-down(lg) {
    margin-block-end: 25px;

    .sorter__counter {
      display: none;
    }
  }
}