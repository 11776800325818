.card.card--cat {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 0;
  padding: 25px 15px 30px;

  figure {
    flex-shrink: 0;
    width: 110px;
    height: 110px;
    margin-block-end: 15px;

    img {
      object-fit: cover;
    }
  }

  .card--cat__body {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: space-between;
    text-align: center;
  }

  @include media-breakpoint-down(md) {
    .btn {
      height: auto;
      margin-block-start: 10px;
      border: 0;
      padding: 0;
      font-weight: 400;
    }
  }

  @include media-breakpoint-up(md) {
    padding-inline-start: 25px;
    padding-inline-end: 25px;

    figure {
      width: 175px;
      height: 175px;
      margin-block-end: 20px;
    }

    .btn {
      margin-block-start: 40px;
    }
  }
}