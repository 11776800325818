.form-switch {
  display: inline-flex;
  position: relative;

  > * {
    cursor: pointer;
  }

  .form-switch__indicator {
    display: block;
    width: 48px;
    height: 24px;
    border-radius: 15px;
    background-color: clr(light-grey);
    position: relative;
    transition: background-color var(--time-fast) ease-out;

    &::after {
      width: 18px;
      height: 18px;
      border-radius: 50%;
      background-color: clr(milk);
      content: '';
      position: absolute;
      top: 3px;
      left: 3px;
      transition: transform var(--time-fast) ease-out;
    }
  }

  input {
    @include fill-w-h(absolute);
    opacity: 0;
    z-index: -1;

    &:checked {
      ~ .form-switch__indicator {
        background-color: clr(blue);

        &::after {
          transform: translateX(calc(100% + 6px));
        }
      }
    }
  }
}