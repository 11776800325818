.hero.hero--product {
  .section-product__config {
    .summary-wrap {
      padding-block-start: 25px;
    }

    .product-summary {
      margin-block-end: 20px;
    }

    .product-explanation {
      margin-block-start: 25px;
      font-size: 16px;
    }

    .tier-prices-holder {
      position: relative;
      z-index: 999;
    }

    .tier-prices {
      border: 1px solid clr(anthracite);
      background: clr(milk);
      position: absolute;
    }
  }

  @include media-breakpoint-down(lg) {
    .section-product__config {
      .accordion-wrap {
        margin-inline-start: calc(-1 * var(--pi-container));
        margin-inline-end: calc(-1 * var(--pi-container));
      }
    }

    .section-product__service {
      .card--service {
        margin-block-start: 15px;
      }
    }
  }

  @include media-breakpoint-down(sm) {
    .section-product__config {
      .quantity-tiers {
        .input-group {
          > * {
            width: 50%;
          }
        }
      }
    }
  }

  @include media-breakpoint-up(sm) {
    .section-product__config {
      .quantity-tiers {
        .input-group {
          .form-control {
            width: 132px;
          }
        }
      }
    }
  }

  @include media-breakpoint-up(lg) {
    .sku,
    .section-product__service {
      width: var(--width-card-service);
    }

    .section-product__config {
      .product-summary {
        margin-block-end: 25px;
      }
    }
  }
}

.hero--product__slider-wrap {
  --size-btn-io: 32px;
  --size-product-thumbnail: 85px;

  figure {
    padding-block-start: 100%;
    position: relative;

    img {
      @include fill-w-h(absolute);
      object-fit: contain;
    }
  }

  .hero--product__slider {
    border: 1px solid clr(porcelain);
    position: relative;

    .badge,
    .hero--product__slider__btn {
      position: absolute;
      z-index: 1;
    }

    .badge {
      top: 0;
      right: 0;
    }

    .hero--product__slider__btn {
      display: flex;
      padding: 15px;
      font-size: 24px;
      color: clr(blue);

      &.hero--product__slider__btn--favorite {
        top: 0;
        left: 0;

        i {
          &:last-child {
            display: none;
          }
        }

        @include media-hover(true) {
          i {
            transition: transform var(--time-norm) ease-out;
          }

          &:hover {
            i {
              transform: scale(1.1);
            }
          }
        }

        &.is-favorite {
          i {
            &:first-child {
              display: none;
            }

            &:last-child {
              display: block;
            }
          }
        }
      }

      &.hero--product__slider__btn--zoom {
        pointer-events: none;
        bottom: 0;
        right: 0;
      }
    }

    .tns-outer {
      @include media-hover(true) {
        + .hero--product__slider__btn--zoom {
          i {
            transition: transform var(--time-norm) ease-out;
          }
        }

        &:hover {
          + .hero--product__slider__btn--zoom {
            i {
              transform: scale(1.1);
            }
          }
        }
      }
    }

    .hero--product__slider__slide {
      display: block;
      padding: 10%;
    }
  }

  .hero--product__slider-tools {
    text-align: center;

    .thumbnail-slider {
      margin-block-start: 20px;

      .thumbnail-slider__track {
        li {
          cursor: pointer;
        }
      }

      .thumbnail-slider__controls {
        button {
          i {
            font-size: 8px;
          }
        }
      }
    }

    .link {
      margin-block-start: 20px;
    }

    &:not(.show-all) {
      .thumbnail-slider {
        .thumbnail-slider__track {
          li {
            + li {
              margin-inline-start: -1px;
            }
          }
        }
      }
    }

    &.show-all {
      .thumbnail-slider {
        .thumbnail-slider__track {
          overflow-x: visible;
          flex-wrap: wrap;

          li {
            &:not(:nth-child(4n+1)) {
              margin-inline-start: -1px;
            }

            &:nth-child(n+5) {
              margin-block-start: -1px;
            }
          }
        }

        .thumbnail-slider__controls {
          visibility: hidden;
        }
      }
    }
  }

  @include media-breakpoint-down(lg) {
    padding-block-end: 25px;

    .hero--product__slider-tools {
      .thumbnail-slider {
        .thumbnail-slider__track {
          margin-inline-end: calc(-1 * var(--pi-container));
          padding-inline-end: var(--pi-container);
        }
      }
    }
  }

  @include media-breakpoint-up(lg) {
    --size-product-thumbnail: 40px;
    width: calc((var(--size-product-thumbnail) * 4) + var(--size-btn-io) - 3px);
    padding-block-end: 25px;

    .hero--product__slider-tools {
      .thumbnail-slider {
        padding-inline-start: calc(var(--size-btn-io) / 2);
        padding-inline-end: calc(var(--size-btn-io) / 2);
      }
    }
  }

  @include media-breakpoint-up(xl) {
    --size-product-thumbnail: 100px;

    .hero--product__slider {
      .icon-zoom-in {
        bottom: 20px;
        right: 20px;
      }
    }
  }
}
