.card.card--suggestion {
  display: flex;
  align-items: center;
  border: 0;
  border-radius: 0;
  padding: 10px 15px;

  figure {
    width: 75px;
    height: 75px;
    margin-inline-end: 15px;
    position: relative;
        
    img {
      @include fill-w-h(absolute);
      object-fit: contain;
      mix-blend-mode: multiply;
    }
  }

  svg {
    width: 14px;
    height: 14px;
    margin-inline-end: 10px;
    fill: clr(dusty);
  }

  .search-suggestion__body {
    .search-suggestion__title {
      + * {
        margin-block-start: 0;
      }
    }

    .price {
      flex-direction: row;
      align-items: center;
    }
  }
}