.section {
  overflow: hidden;
  padding-block-start: var(--pb-section);
  padding-block-end: var(--pb-section);

  .container > .btn {
    margin-block-start: 30px;
  }

  .section__intro {
    text-align: center;

    + * {
      margin-block-start: 30px;
    }
  }

  .section__footer,
  .section__discount {
    margin-block-start: 30px;
  }

  .section__footer {
    .link {
      color: clr(anthracite);
    }
  }

  &:not([class*='bg-']) + .section:not([class*='bg-']):not(.section--tabs) {
    border-block-start: 1px solid clr(porcelain);
  }

  &.section--tabs {
    padding-block-start: 0;
    padding-block-end: 0;
  }

  @include media-breakpoint-down(sm) {
    .section__footer {
      > * + * {
        margin-block-start: 30px;
      }
    }
  }

  @include media-breakpoint-up(sm) {
    .section__footer {
      display: flex;
      align-items: center;
      justify-content: space-between;
            
      .btn {
        margin-block-start: 0;
      }
    }
  }

  @include media-breakpoint-up(lg) {
    .container > .btn {
      margin-block-start: 50px;
    }
  }
}