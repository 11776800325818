.navbar__search-rating {
  width: calc(100% - 140px);
  position: relative;

  @include media-breakpoint-down(xl) {
    position: absolute;
    bottom: 7.5px;
    right: 10px;
    z-index: 1;
  }

  @include media-breakpoint-up(xl) {
    flex-grow: 1;
    margin-inline-start: calc(var(--pi-container) * 4);
    margin-inline-end: calc(var(--pi-container) * 4);
  }
}

.navbar__search {
  position: relative;

  .navbar__search__input-wrap {
    position: relative;
        
    .form-control {
      &::-webkit-search-decoration,
      &::-webkit-search-cancel-button,
      &::-webkit-search-results-button,
      &::-webkit-search-results-decoration {
        display: none;
      }
    }

    button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: var(--height-control);
      height: var(--height-control);
      font-size: 23px;
      position: absolute;
      top: 0;
      right: 0;

      @include media-hover(true) {
        i {
          transition: transform var(--time-fast) ease-out;
        }

        &:hover {
          i {
            transform: scale(1.1);
          }
        }
      }
    }
  }

  .navbar__search__suggestions {
    @include overflow(y);
    width: 100%;
    max-height: calc(100vh - var(--height-navbar));
    border: 1px solid clr(light-grey);
    border-bottom-left-radius: var(--border-radius);
    border-bottom-right-radius: var(--border-radius);
    background-color: clr(milk);
    position: absolute;
    top: calc(100% - 1px);
    left: 0;
    z-index: 1;
    animation: scale-drop-down-typeahead var(--time-norm) cubic-bezier(.1, 1.45, .35, 1);
    
    body:not(.show-suggestions) & {
      display: none;
    }
  }

  body.show-suggestions & {
    .navbar__search__input-wrap {
      .form-control {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
  }

  @include media-breakpoint-down(xl) {
    .navbar__search__input-wrap {
      --height-control: 45px;
            
      .form-control,
      button {
        color: clr(milk);
      }
            
      .form-control {
        border: 0;
        background-color: rgba(clr(milk), .25);

        &::placeholder {
          color: rgba(clr(milk), .75);
        }
      }
    }
  }

  @include media-breakpoint-up(xl) {
    .navbar__search__input-wrap {
      button {
        color: clr(blue);
      }
    }
  }
}