.page-footer__payment {
  display: flex;
  flex-direction: column;
  align-items: center;

  .page-footer__payment__inner {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60px;
  }

  img {
    height: 30px;

    + img {
      margin-inline-start: 35px;
    }
  }

  @include media-breakpoint-down(lg) {
    border-block-start: 1px solid clr(porcelain);
  }

  @include media-breakpoint-up(lg) {
    .page-footer__payment__inner {
      height: 80px;
      border-block-start: 1px solid clr(porcelain);
    }

    img {
      + img {
        margin-inline-start: 60px;
      }
    }
  }
}