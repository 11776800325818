.filters {
  position: relative;

  .filters__grid {
    display: grid;
    gap: 12px;
  }

  .active-filters {
    margin-block-start: 14px;

    .active-filters__inner,
    .active-filters__filters {
      display: flex;
      flex-wrap: wrap;
    }

    .active-filters__filters {
      .active-filter {
        display: flex;
        align-items: center;
        margin-block-start: 10px;
        margin-inline-end: 10px;
        border: 1px solid clr(light-grey);
        border-radius: var(--border-radius);
        padding: 5px 10px;
        color: clr(anthracite);

        i {
          margin-inline-start: 5px;
          font-size: 10px;
          color: clr(dusty);
          transition: color var(--time-fast) ease-out;
        }

        .active-filter__key {
          &::first-letter {
            text-transform: uppercase;
          }
        }

        @include media-hover(true) {
          &:hover {
            i {
              color: clr(orange);
            }
          }
        }
      }
    }

    .link {
      flex-shrink: 0;
    }
  }

  .sorter {
    .sorter__group {
      display: flex;
      align-items: center;

      .form-select .form-select__inner {
        filter: drop-shadow(0 0 2px clr(orange));
      }
    }
  }

  @include media-breakpoint-down(lg) {
    margin-block-end: 20px;

    .btn--primary {
      .icon-cross {
        @include center-y(right, var(--pi-btn));
        margin-inline-end: 0 !important;
        font-size: 12px;
      }
    }

    .filters__inner {
      width: 100%;
      border-width: 0 1px 1px;
      border-style: solid;
      border-color: clr(light-grey);
      border-bottom-left-radius: var(--border-radius);
      border-bottom-right-radius: var(--border-radius);
      background-color: clr(broken-white);
      position: absolute;
      top: 100%;
      left: 0;
      z-index: 99;
    }

    .sorter,
    .filters__wrap {
      padding-inline-start: 15px;
      padding-inline-end: 15px;
    }

    .sorter {
      margin-block-end: 0;
      padding-block-start: 10px;
      padding-block-end: 10px;
      background-color: clr(porcelain);

      .sorter__group {
        .form-select {
          flex-grow: 1;
        }
      }
    }

    .filters__wrap {
      padding-block-start: 15px;
      padding-block-end: 15px;
    }

    .active-filters {
      .link {
        margin-block-start: 12px;
      }
    }

    &:not(.open) {
      .btn--primary {
        .icon-cross {
          display: none;
        }
      }
    }

    &.open {
      .btn--primary {
        border-color: clr(light-grey);
        border-block-end: 0;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        background-color: clr(broken-white);

        &::before {
          display: none;
        }

        i {
          color: clr(blue);
        }

        span {
          color: clr(dark-grey);
        }
      }
    }
  }

  @include media-breakpoint-up(lg) {
    .filters__inner {
      &.collapse {
        display: flex;
        flex-direction: column;
      }
    }

    .sorter {
      display: flex;
      align-items: center;
      justify-content: space-between;
      order: 1;
      margin-block-start: 30px;
      padding-block-start: 25px;
      position: relative;

      &::before {
        @include center-x(top, 0);
        width: 100vw;
        height: 1px;
        background-color: clr(porcelain);
        content: '';
      }

      .form-select {
        width: 230px;
      }
    }

    .filters__grid {
      grid-template-columns: repeat(4, minmax(0, 1fr));
    }

    .active-filters {
      display: flex;
      justify-content: space-between;
    }
  }

  @include media-breakpoint-up(xxl) {
    .filters__grid {
      grid-template-columns: repeat(5, minmax(0, 1fr));
    }
  }
}
