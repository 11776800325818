.table.table--compare {
  width: 100%;

  tr {
    th,
    td {
      width: calc(100% / 4);
      min-width: 180px;
      padding: 15px 20px;
    }

    &:nth-child(-n+5) {
      background-color: transparent !important;

      &:not(:first-child) {
        th,
        td {
          padding-block-start: 7px;
        }
      }

      &:not(.actions) {
        th,
        td {
          padding-block-end: 7px;
        }
      }
    }
  }

  @include media-breakpoint-down(md) {
    width: auto;
    table-layout: fixed;

    tr {
      th,
      td {
        &:first-child {
          width: 0;
          min-width: 0 !important;
          max-width: 0;
          padding-inline-start: 0;
          padding-inline-end: 0;
          white-space: nowrap;

          h5 {
            margin-inline-start: 20px;
          }
        }

        &:nth-child(2) {
          padding-inline-start: 20px;
        }
      }

      &:not(:nth-child(-n+5)) {
        td {
          &:not(:first-child) {
            padding-block-start: 45px;
          }
        }
      }
    }
  }

  @include media-breakpoint-up(md) {
    tr {
      th,
      td {
        min-width: 224px;
      }
    }
  }
}

.image-wrap {
  width: calc(100% - 13px);
  max-width: 257px;
  position: relative;

  .image-wrap__thumb {
    display: block;
    border: 1px solid clr(porcelain);
    border-radius: var(--border-radius);
    padding: 5px;
    position: relative;
        
    figure {
      padding-block-start: 91.875%;
      position: relative;

      img {
        @include fill-w-h(absolute);
        object-fit: contain;
      }
    }

    @include media-hover(true) {
      transition: border-color var(--time-fast) ease-out;

      &:hover {
        border-color: clr(blue);
      }
    }
  }

  .btn.btn--io {
    position: absolute;
    top: -15px;
    right: -15px;
    z-index: 1;
  }
}