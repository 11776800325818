// Textual
body > *:not(.navbar) {
  p:not(:empty),
  small,
  ul[class*='list'],
  ol[class*='list'],
  table,
  blockquote,
  time {
    + *:not(.btn):not(div) {
      margin-block-start: 15px;
    }
  }
}

caption {
  color: clr(anthracite);
}

b,
strong {
  font-weight: 500;
}

small {
  @include small;
}

ul,
ol {
  &[class*='list']:not(.list-icon) {
    > li {
      padding-inline-start: 20px;
      position: relative;

      &::before {
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }
}

ul {
  &.list {
    > li {
      &::before {
        content: '•';
        font-size: 24px;
      }
    }
  }

  &.list-chevron,
  &.list-check {
    > li {
      &::before {
        font-family: $icomoon-font-family;
      }
    }
  }

  &.list-chevron {
    > li {
      &::before {
        content: $icon-chevron-right;
        font-size: 14px;
        color: clr(light-grey);
      }
    }
  }

  &.list-check {
    > li {
      &::before {
        content: $icon-check;
        font-size: 10px;
        color: clr(orange);
      }
    }
  }
}

ol.list {
  counter-reset: ol-counter;

  > li {
    counter-increment: ol-counter;

    &::before {
      content: counter(ol-counter) '. ';
      font-weight: 700;
    }
  }
}

// Anchors
p a,
small a,
.form-check__text a,
.link {
  color: clr(blue);

  @include media-hover(true) {
    transition: opacity var(--time-fast) ease-out;

    &:hover {
      opacity: .6;
    }
  }
}

p a,
small a,
.form-check__text a {
  text-decoration: underline;
}

.link {
  display: inline-flex;
  align-items: center;

  i {
    margin-inline-end: 5px;
    font-size: 16px;

    &[class*='arrow'],
    &[class*='chevron'] {
      font-size: 7px;
    }
  }
}