.table.table--simple {
  width: 100%;

  th,
  td {
    padding: 12px 10px;
    line-height: 1.1428571429;
    color: clr(anthracite);
    vertical-align: middle;

    &:first-child {
      padding-inline-start: 24px;
    }
        
    &:last-child {
      padding-inline-end: 24px;
    }
  }
    
  tbody {
    border-width: 2px 0;
    border-style: solid;
    border-color: clr(porcelain);

    tr {
      td {
        &:last-child {
          white-space: nowrap;
        }
      }
    }
  }

  &.table--simple--tier-price {
    tbody {
      tr {
        td {
          &:last-child {
            color: clr(orange);
          }
        }
      }
    }
  }
}