.card.card--sidebar {
  .card--sidebar__toggle,
  ul li a {
    display: flex;
    align-items: center;
    width: 100%;
    border-block-end: 1px solid clr(porcelain);
    padding: 15px;
    font-weight: 500;
    color: clr(anthracite);
    text-transform: uppercase;
    letter-spacing: 1px;

    i {
      display: flex;
      flex-shrink: 0;
      justify-content: center;
      width: 21px;
            
      &:not(.icon-chevron-down) {
        margin-inline-end: 15px;
      }
    }
  }

  .card--sidebar__toggle {
    .icon-chevron-down {
      margin-inline-start: auto;
      padding-inline-start: 15px;
      font-size: 10px;
      transition: transform var(--time-norm) ease-out;
    }

    &.open {
      .icon-chevron-down {
        transform: scaleY(-1);
      }
    }
  }

  ul li {
    ul li a {
      padding-inline-start: 30px;
    }
    
    &:not(.active) {
      > a {
        @include media-hover(true) {
          &:hover {
            background-color: clr(broken-white);
          }
        }
      }
    }

    &.active {
      > a {
        color: clr(orange);
      }
    }
  }

  .card--sidebar__logout {
    padding: 30px;

    .btn {
      width: 100%;
    }
  }

  @include media-breakpoint-down(lg) {
    margin-block-end: 30px;

    ul {
      li.active {
        display: none;
      }
    }
  }

  @include media-breakpoint-up(lg) {
    ul {
      li a {
        i {
          width: 24px;
          font-size: 19px;
        }
      }
    }

    .collapse:not(.show) {
      display: block;
    }
  }
}