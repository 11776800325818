* {
  scroll-margin-block-start: calc(var(--height-navbar-middle) + var(--height-navbar-bottom));

  &:focus {
    outline: 0;
  }
}

::selection {
  background-color: clr(blue);
  color: clr(milk);
}

html {
  scroll-behavior: smooth;
}

body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: clr(milk);

  > main {
    flex-grow: 1;
  }

  > footer {
    overflow-x: hidden;
  }

  @include media-breakpoint-up(lg) {
    &::after {
      @include fill-w-h(fixed);
      background-color: rgba(clr(anthracite), .5);
      content: '';
      z-index: 99;
      transition: var(--time-slow) opacity var(--cubic-bezier), var(--time-slow) visibilty var(--cubic-bezier);
    }
        
    &:not(.sub-open) {
      &::after {
        opacity: 0;
        visibility: hidden;
      }
    }

    &.sub-open {
      overflow: hidden;
    }
  }
}

.w-100 {
  width: 100%;
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

hr {
  margin-block-start: 5px;
  margin-block-end: 25px;
  border-block-start-color: clr(porcelain);
}

.semi-transparent-underlay {
  opacity: .15;
  display: block;
  width: 100%;
  height: 100%;
  background-color: clr(blue);
  position: fixed;
  top: 0;
  left: 0;
  animation: show-underlay var(--time-norm) ease-in-out;
    
  &.semi-transparent-underlay--under-header {
    z-index: 12;
  }
    
  body:not(.show-suggestions) & {
    visibility: hidden;
    animation: hide-underlay var(--time-norm) ease-in-out;
  }
}

.mobile-fw {
  @include media-breakpoint-down(lg) {
    margin-inline-start: calc(-1 * var(--pi-container));
    margin-inline-end: calc(-1 * var(--pi-container));
  }
}