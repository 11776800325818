.card.card--logo {
  display: block;
  padding: 5px;
  background-color: clr(milk);

  figure {
    padding-block-start: 100%;

    img {
      object-fit: contain;
      filter: grayscale(1);
    }
  }

  @include media-breakpoint-up(lg) {
    padding: 15px;
  }
}