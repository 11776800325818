.table.table--price-summary {
  border: 1px solid clr(accent-orange);
  padding: .5rem;

  table {
    width: 100%;
  }

  th,
  td {
    padding-block-start: 5px;
    padding-block-end: 5px;
    line-height: 1.2142857143;
    color: clr(anthracite);
    vertical-align: middle;

    &:first-child {
      margin-inline-end: auto;
    }

    &:not(:first-child) {
      padding-inline-start: 10px;
      text-align: right;
    }

    &:not(:last-child) {
      padding-inline-end: 10px;
    }
  }

  tbody {
    tr:last-child > * {
      padding-block-end: 15px;
    }
  }

  tfoot:not(.no-border) {
    border-block-start: 1px solid clr(porcelain);
  }

  tfoot {
    tr:first-child > * {
      padding-block-start: 15px;
    }
  }

  .section--cart &.table--price-summary--cart {
    @include media-breakpoint-down(lg) {
      margin-block-start: 30px;
    }
  }
}
