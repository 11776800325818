.card.card--uploader {
  .card--uploader__text {
    display: inline-block;
    padding-inline-end: 25px;
    position: relative;

    .tooltip-wrap {
      position: absolute;
      top: 3px;
      right: 0;
    }
  }
}