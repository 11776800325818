// generated with http://chir.ag/projects/name-that-color
// stylelint-disable color-no-hex
$colors: (
  primary: (
    blue: #2980b9,
    orange: #ff7043,
    milk: #fff,
    broken-white: #f7f8f8,
    anthracite: #17202a,
  ),
  secondary: (
    dark-grey: #515a5a,
    light-grey: #c3c4c6,
    boulder: #777,
    porcelain: #e5e9ea,
    gallery: #eee,
    alabaster: #f7f7f7,
    dusty: #999,
  ),
  accent: (
    accent-blue: #3c92cb,
    accent-orange: #ff8443,
  ),
  tertiary: (
    positive: #37a048,
    negative: #be4200,
    carbon: #000,
  ),
);
// stylelint-enable color-no-hex

// Color generation
@function clr($find) {
  @each $keyName, $key in $colors {
    @each $name, $value in $key {
      @if $find == $name {
        @return $value;
      }
      // @else {
      //     @error 'Can\'t find a color named #{$name}';
      // }
    }
  }
}

// stylelint-disable declaration-no-important
@each $keyName, $key in $colors {
  @each $name, $value in $key {
    .bg-#{'' + $name} {
      background-color: $value !important;
    }
  }
}

@each $keyName, $key in $colors {
  @each $name, $value in $key {
    .text-#{'' + $name} {
      color: $value !important;

      > * {
        color: inherit;
      }
    }
  }
}
// stylelint-enable declaration-no-important