.card {
  border: 1px solid clr(porcelain);
  border-radius: var(--border-radius);
  position: relative;

  .card__header,
  .card__body {
    padding-inline-start: 20px;
    padding-inline-end: 20px;
  }

  .card__header {
    display: flex;
    align-items: center;
    padding-block-start: 10px !important;
    padding-block-end: 10px !important;
    background-color: clr(broken-white);
  }

  > .form-check {
    display: block;
  }

  figure {
    overflow: hidden;
    position: relative;

    img {
      @include fill-w-h(absolute);
    }
  }

  .card__body {
    padding-block-start: 25px;
    padding-block-end: 25px;
  }

  &:not(.card--budget):not(.card--has-tooltip):not(.card--billing):not(.card--shipping) {
    overflow: hidden;
  }

  @include media-breakpoint-down(lg) {
    .card__header {
      border-width: 1px 0;
      border-style: solid;
      border-color: clr(porcelain);
    }

    &.mobile-fw {
      body:not(.pdf) & {
        border: 0;
        border-radius: 0;

        &.mobile-fw--border-be {
          border-block-end-width: 1px;
        }
      }
    }
  }

  @include media-breakpoint-up(lg) {
    .card__header {
      + table,
      + .card__body,
      + * .card__body {
        border-block-start: 1px solid clr(porcelain);
      }
    }
  }
}

a.card,
.card--product {
  @include media-hover(true) {
    &:hover {
      box-shadow: 0 0 10px rgba(clr(carbon), .1);
      z-index: 1;
    }
  }
}
