@import "variables";

@font-face {
  font-family: '#{$icomoon-font-family}';
  src:
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff2?7ncx7j') format('woff2'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?7ncx7j') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?7ncx7j') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?7ncx7j##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-basket {
  &:before {
    content: $icon-basket; 
  }
}
.icon-boxes {
  &:before {
    content: $icon-boxes; 
  }
}
.icon-chat {
  &:before {
    content: $icon-chat; 
  }
}
.icon-check {
  &:before {
    content: $icon-check; 
  }
}
.icon-chevron-down {
  &:before {
    content: $icon-chevron-down; 
  }
}
.icon-chevron-left {
  &:before {
    content: $icon-chevron-left; 
  }
}
.icon-chevron-right {
  &:before {
    content: $icon-chevron-right; 
  }
}
.icon-clock {
  &:before {
    content: $icon-clock; 
  }
}
.icon-cross {
  &:before {
    content: $icon-cross; 
  }
}
.icon-duplicate {
  &:before {
    content: $icon-duplicate; 
  }
}
.icon-envelope {
  &:before {
    content: $icon-envelope; 
  }
}
.icon-filter {
  &:before {
    content: $icon-filter; 
  }
}
.icon-heart {
  &:before {
    content: $icon-heart; 
  }
}
.icon-heart-o {
  &:before {
    content: $icon-heart-o; 
  }
}
.icon-info {
  &:before {
    content: $icon-info; 
  }
}
.icon-quotation {
  &:before {
    content: $icon-quotation; 
  }
}
.icon-leaf {
  &:before {
    content: $icon-leaf; 
  }
}
.icon-menu {
  &:before {
    content: $icon-menu; 
  }
}
.icon-not-allowed {
  &:before {
    content: $icon-not-allowed; 
  }
}
.icon-paint-brush {
  &:before {
    content: $icon-paint-brush; 
  }
}
.icon-pencil {
  &:before {
    content: $icon-pencil; 
  }
}
.icon-phone {
  &:before {
    content: $icon-phone; 
  }
}
.icon-play {
  &:before {
    content: $icon-play; 
  }
}
.icon-print {
  &:before {
    content: $icon-print; 
  }
}
.icon-scale {
  &:before {
    content: $icon-scale; 
  }
}
.icon-search {
  &:before {
    content: $icon-search; 
  }
}
.icon-share {
  &:before {
    content: $icon-share; 
  }
}
.icon-social-facebook {
  &:before {
    content: $icon-social-facebook; 
  }
}
.icon-social-instagram {
  &:before {
    content: $icon-social-instagram; 
  }
}
.icon-social-linkedin {
  &:before {
    content: $icon-social-linkedin; 
  }
}
.icon-social-pinterest {
  &:before {
    content: $icon-social-pinterest; 
  }
}
.icon-social-twitter {
  &:before {
    content: $icon-social-twitter; 
  }
}
.icon-social-whatsapp {
  &:before {
    content: $icon-social-whatsapp; 
  }
}
.icon-social-youtube {
  &:before {
    content: $icon-social-youtube; 
  }
}
.icon-star {
  &:before {
    content: $icon-star; 
  }
}
.icon-tag {
  &:before {
    content: $icon-tag; 
  }
}
.icon-trash {
  &:before {
    content: $icon-trash; 
  }
}
.icon-truck {
  &:before {
    content: $icon-truck; 
  }
}
.icon-truck-fast {
  &:before {
    content: $icon-truck-fast; 
  }
}
.icon-truck-fast-alt {
  &:before {
    content: $icon-truck-fast-alt; 
  }
}
.icon-upload {
  &:before {
    content: $icon-upload; 
  }
}
.icon-user {
  &:before {
    content: $icon-user; 
  }
}
.icon-zoom-in {
  &:before {
    content: $icon-zoom-in; 
  }
}

