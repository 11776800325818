.section.section--reviews {
  .section__intro {
    img {
      height: 30px !important;

      @include media-breakpoint-up(lg) {
        height: 60px !important;
      }
    }
  }

  .tns-ovh {
    overflow: visible;
  }

  .review-slider__track {
    display: flex;

    .card--review {
      height: 100%;
    }
  }
}