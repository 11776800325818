.tool-wrap {
  display: flex;

  .section-product__service & {
    display: flex;
    justify-content: space-evenly;
    margin-block-start: 25px;
    margin-block-end: 20px;
    border-block-start: 1px solid clr(porcelain);
    padding-block-start: 20px;
    
    @include media-breakpoint-up(lg) {
      margin-block-start: 20px;
      margin-block-end: 25px;
      padding-block-start: 15px;
    }
  }
}

.tool {
  a,
  button {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: clr(blue);

    .tool__icon {
      display: flex;
      position: relative;

      i {
        font-size: 22px;
      }

      .counter {
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: var(--size-counter);
        height: var(--size-counter);
        border-radius: calc(var(--size-counter) / 2);
        padding-inline-start: 3px;
        padding-inline-end: 3px;
        background-color: clr(orange);
        font-weight: 700;
        font-size: 8px;
        line-height: 1;
        color: clr(milk);
        position: absolute;
        top: -4px;
        right: -8px;
      }
    }

    .tool__text {
      margin-block-start: 10px;
      font-weight: 500;
      font-size: 13px;
      line-height: 15px;
    }

    @include media-hover(true) {
      &:hover {
        color: clr(accent-blue);
      }
    }
  }

  + .tool {
    margin-inline-start: 18px;
  }

  &.tool--share {
    position: relative;
    z-index: 2;

    .share__modal {
      transition: visibility var(--time-fast) ease-out, opacity var(--time-fast) ease-out;

      .social-list {
        margin-block-start: 20px;
        border: 1px solid clr(porcelain);
        padding: 21px;
        background-color: clr(milk);
        filter: drop-shadow(0 2px 22px rgba(clr(carbon), .07));
        position: absolute;
        right: -5px;

        &::before,
        &::after {
          width: 0;
          height: 0;
          border: solid transparent;
          content: '';
          pointer-events: none;
          position: absolute;
        }
                
        &::before {
          margin-inline-start: -21px;
          border-width: 21px;
          border-color: transparent;
          border-block-end-color: clr(porcelain);
          right: 0;
        }
                
        &::after {
          margin-inline-start: -20px;
          border-width: 20px;
          border-color: transparent;
          border-block-end-color: clr(milk);
          right: 1px;
        }
      }
    }

    &.tool--share--bottom {
      .social-list {
        margin-block-start: 20px;
        top: calc(100% + 5px);
    
        &::before,
        &::after {
          bottom: 100%;
        }
      }
    }
    
    &.tool--share--top {
      .share__modal {
        .social-list {
          margin-block-end: 20px;
          bottom: calc(100% + 5px);
        
          &::before,
          &::after {
            top: 100%;
            transform: scaleY(-1);
          }
        }
      }
    }

    &:not(.show) {
      .share__modal {
        opacity: 0;
        visibility: hidden;
      }
    }
  }
}