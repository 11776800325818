.breadcrumbs {
  @include small;
  display: flex;
  align-items: center;
  margin-block-end: 20px;

  li {
    + li {
      &::before {
        margin-inline-start: 7px;
        margin-inline-end: 7px;
        content: $icon-chevron-right;
        font-family: $icomoon-font-family;
        font-size: 7px;
        line-height: 1;
        color: clr(light-grey);
      }
    }

    &:last-child {
      color: rgba(clr(dark-grey), .5);
    }
  }

  .container > & {
    border-block-start: 1px solid clr(porcelain);
    padding-block-start: 15px;
  }
}