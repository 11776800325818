@use 'sass:color';

@mixin icon {
  content: $icon-chevron-down;
  font-family: $icomoon-font-family;
  font-size: var(--font-size-select-icon);
  line-height: 1;
  color: clr(blue);
  pointer-events: none;
  transition: transform var(--time-norm) ease-out;
}

.form-select {
  position: relative;

  .form-select__inner {
    filter: drop-shadow(0 0 1px color.scale(clr(light-grey), $lightness: -32%));
    position: relative;
    z-index: 10;

    &::before {
      @include fill-w-h(absolute);
      content: '';
      transition: opacity var(--time-norm) ease-out;
    }
  }

  .form-select__btn,
  .form-select__option {
    @include form-control;
    padding-inline-end: var(--pie-select);
    color: inherit;
    cursor: pointer;
  }

  .form-select__btn {
    border: 0;
    white-space: nowrap;
    user-select: none;
    position: relative;

    &::after {
      @include icon;
      flex-shrink: 0;
      margin-inline-start: auto;
      padding-inline-start: var(--pie-select);
    }

    span {
      overflow: hidden;
      text-overflow: ellipsis;
      pointer-events: none;

      &::first-letter {
        text-transform: uppercase;
      }
    }
  }

  .form-select__list {
    width: 100%;
    position: absolute;
    top: 50%;
    left: 0;
    z-index: -1;

    .form-select__list__body {
      overflow: hidden;
      border-radius: 0 0 var(--border-radius) var(--border-radius);
      padding-block-start: calc(var(--height-control) / 2);
      background-color: clr(milk);
    }

    .form-select__list__scroller {
      @include overflow(y);
      max-height: calc(100vh - var(--height-navbar));
      padding-block-start: 1px;
    }
  }

  .form-select__option {
    height: auto !important;
    border-width: 1px 0 0;
    border-radius: 0;
    background-color: transparent;
    line-height: normal;
    position: relative;

    &.equals-selected:first-child + .form-select__option,
    &:first-child {
      border-block-start: 0;
    }

    &.disabled {
      cursor: default;
    }

    &.equals-selected {
      display: none;
    }
  }

  + .form-select {
    margin-block-start: 10px;
  }

  &.active {
    z-index: 11;

    .form-select__inner {
      &::before {
        filter: drop-shadow(0 4px 14px rgba(clr(carbon), .05));
      }
    }

    .form-select__btn {
      &::after {
        transform: scaleY(-1);
      }
    }
  }

  &.error {
    .form-select__inner {
      filter: none; // drop-shadow(0 0 1px color.scale(clr(negative), $lightness: -32%));
      border: 1px solid clr(negative);
    }
  }

  &:not(.form-select--custom) {
    .form-select__list {
      .form-select__list__scroller {
        border-block-start: 1px solid clr(light-grey);
      }
    }

    .form-select__option {
      min-height: var(--height-control);
      padding-block-start: var(--p-control);
      padding-block-end: var(--p-control);

      @include media-hover(true) {
        &:not(.disabled) {
          &:hover {
            background-color: clr(broken-white);
          }
        }
      }
    }

    select[name*='country'] + .form-select__inner .form-select__list__scroller {
      max-height: 300px;
    }

    @include media-hover(false) {
      &::after {
        @include icon;
        position: absolute;
        top: calc((var(--height-control) - var(--font-size-select-icon)) / 2);
        right: var(--pie-select);
      }

      select {
        + .form-select__inner {
          display: none;
        }

        &.form-control {
          padding-inline-end: calc((var(--pie-select) * 2) + 15px);
        }
      }
    }

    @include media-hover(true) {
      select {
        display: none;
      }
    }
  }

  &.form-select--custom {
    .form-select__list {
      .form-select__list__body {
        padding-block-start: calc(var(--height-control) / 2 + 10px);
      }

      .form-select__list__scroller {
        padding-inline-start: 15px;
        padding-inline-end: 15px;

        .btn {
          width: 100%;
          margin-block-start: 15px;
        }
      }
    }
  }

  &.form-select--checkbox {
    .form-select__list {
      .form-select__list__scroller {
        padding-block-end: 25px;
      }
    }
  }

  &.form-select--range {
    .form-select__list {
      .form-select__list__scroller {
        padding-block-end: 15px;

        .form-select--range__inputs {
          display: flex;
          align-items: center;

          span {
            flex-shrink: 0;
            margin-inline-end: 15px;
          }

          .form-control {
            flex-grow: 1;

            + span {
              margin-inline-start: 15px;
            }
          }
        }
      }
    }
  }

  @include media-breakpoint-up(lg) {
    &.form-select--custom {
      .form-select__btn {
        &::after {
          color: clr(light-grey);
        }
      }

      &:not(.active) {
        .form-select__btn {
          background-color: clr(broken-white);
          color: clr(anthracite);
        }
      }
    }
  }
}
