.page-footer__middle {
  background-color: clr(broken-white);
    
  .page-footer__middle__inner {
    border-block-start: 1px solid clr(gallery);
    padding-block-start: 40px;
    padding-block-end: 80px;
  }

  .btn {
    margin-block-start: 20px;
  }

  th {
    padding-inline-end: 5px;
    font-weight: 500;
  }

  @include media-breakpoint-down(lg) {
    .row {
      --bs-gutter-y: 40px;
    }
  }
}