.card.card--budget {
  padding: 15px 15px 20px;
  background-color: clr(broken-white);

  .btn {
    width: 100%;
  }

  @include media-breakpoint-down(lg) {
    h4 {
      text-align: center;
    }
  }

  @include media-breakpoint-up(lg) {
    padding: 20px;

    .form-group {
      margin-block-end: 0;
    }
  }
}