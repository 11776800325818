.alert {
  display: flex;
  margin-block-start: 20px;
  border-radius: var(--border-radius);
  padding: 16px 48px 16px 16px;
  font-size: 12px;
  line-height: 16px;
  color: clr(anthracite);
  text-align: left;
  position: relative;

  i {
    margin-inline-end: 10px;
    font-size: 16px;
  }

  .btn-close {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    margin-block-start: 0 !important; // stylelint-disable-line declaration-no-important
    padding: 16px;
    position: absolute;
    top: 0;
    right: 0;
        
    &::before {
      content: $icon-cross;
      font-family: $icomoon-font-family;
      font-size: 21px;
      line-height: 1;
    }

    @include media-hover(true) {
      span {
        transition: transform var(--time-norm) ease-out;
      }
            
      &:hover {
        span {
          transform: scale(1.25);
        }
      }
    }
  }

  &:last-child {
    margin-block-end: 20px;
  }

  &.alert-info {
    background-color: rgba(clr(orange), .1);

    i,
    strong {
      color: clr(orange);
    }
  }

  &.alert-success {
    background-color: clr(positive);

    i,
    strong {
      color: clr(milk);
    }
  }

  &.alert-danger {
    background-color: clr(negative);

    i,
    strong {
      color: clr(milk);
    }
  }

  @include media-breakpoint-down(sm) {
    strong {
      display: block;
    }
  }
}