.row.g-form {
  --bs-gutter-x: var(--width-column-gutter-row);
  --bs-gutter-y: var(--height-column-gutter-row);
}

fieldset {
  + fieldset {
    margin-block-start: 60px;
  }
}

.form-label {
  display: block;
  font-weight: 500;
  color: clr(anthracite);
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-block-start: 5px;
  color: clr(negative);
  font-weight: 600;
}

.is-invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-feedback {
  display: block;
}
