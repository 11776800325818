.navbar-top {
  border-block-end: 1px solid clr(porcelain);
  font-size: 12px;
  line-height: 14px;
  color: clr(anthracite);
  position: relative;
  z-index: 101;

  .container {
    height: var(--height-navbar-top);
  }

  a {
    display: inline-flex;
    align-items: center;
  }

  .navbar__contact {
    display: flex;
    align-items: center;
    
    .navbar__contact__text {
      margin-inline-end: 40px;
    }
  }
    
  .navbar__service,
  .navbar__links a {
    @include media-hover(true) {
      &:hover {
        color: clr(dark-grey);
      }
    }
  }
    
  .navbar__service {
    i {
      margin-inline-start: 10px;
      font-size: 10px;
    }
  }
}

.navbar-main {
  position: sticky;
  top: 0;
  z-index: 100;
}

.navbar-top,
.navbar__middle,
.navbar__bottom {
  .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.navbar-top,
.navbar__middle {
  background-color: clr(milk);

  a,
  button {
    @include media-hover(true) {
      transition: color var(--time-fast) ease-out;
    }
  }
}

.navbar__middle {
  .container {
    height: var(--height-navbar-middle);
  }

  .navbar__logo {
    display: block;

    img {
      width: auto;
      height: 30px !important;

      @include media-breakpoint-up(lg) {
        height: 60px !important;
      }
    }
  }
}

.navbar__bottom {
  background-color: clr(blue);
  position: relative;

  .container {
    height: var(--height-navbar-bottom);
  }
}

@include media-breakpoint-down(xl) {
  .navbar-top,
  .navbar__middle,
  .navbar__bottom {
    .container {
      padding-inline-start: 15px;
    }
  }

  .navbar-top,
  .navbar__middle {
    .container {
      padding-inline-end: 15px;
    }
  }

  .navbar__bottom {
    .container {
      padding-inline-end: 10px;
    }

    .navbar__toggle-menu {
      flex-shrink: 0;
      color: clr(milk);
    }
  }
}

@include media-breakpoint-down(lg) {
  .navbar__middle {
    .tool-wrap {
      .tool {
        .tool__text {
          display: none;
        }
      }
    }
  }
}