// Center Horizontally
@mixin center-x($position, $distance) {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);

  @if $position == 'top' {
    top: $distance;
    bottom: auto;
  } @else {
    top: auto;
    bottom: $distance;
  }
}

// Center Vertically
@mixin center-y($position, $distance) {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);

  @if $position == 'left' {
    left: $distance;
    right: auto;
  } @else {
    left: auto;
    right: $distance;
  }
}

// Center both Horizontally and Vertically
@mixin center-x-y($position) {
  position: $position;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

// Fill both Width and Height
@mixin fill-w-h($position) {
  width: 100%;
  height: 100%;
  position: $position;
  top: 0;
  left: 0;
}

// Overflow
@mixin overflow($axis) {
  -webkit-overflow-scrolling: touch;

  @if $axis == 'x' {
    overflow-x: auto;
  } @else {
    overflow-y: auto;
  }
}

// Content animation
@mixin animation-content {
  animation: {
    name: content;
    direction: normal;
    duration: var(--time-fast);
    timing-function: ease-in-out;
    iteration-count: 1;
  }

  @keyframes content {
    from {
      opacity: 0;
      transform: translateY(5%);
    }

    to {
      opacity: 1;
      transform: translateY(0%);
    }
  }
}

// Hover query
@mixin media-hover($boolean) {
  $hover: null;
  $pointer: null;

  @if $boolean == true {
    $hover: hover;
    $pointer: fine;
  } @else {
    $hover: none;
    $pointer: coarse;
  }

  @media (hover: $hover) and (pointer: $pointer) {
    @content;
  }
}

// Checkmark
@mixin checkmark($width, $height, $thickness, $color, $bottom-radius) {
  width: $width;
  height: $height;
  border-block-start: $thickness solid $color;
  border-inline-end: $thickness solid $color;
  border-top-right-radius: $bottom-radius;
  content: '';
  transform: scaleX(-1) rotate(135deg);
  transform-origin: left top;
}

// stylelint-disable plugin/no-low-performance-animation-properties
@mixin checkmark-animation($width, $height) {
  animation-name: checkmark;
  animation-duration: var(--time-slug);
  animation-timing-function: ease-out;

  @keyframes checkmark {
    0% {
      opacity: 1;
      width: 0;
      height: 0;
    }

    20% {
      opacity: 1;
      width: $width;
      height: 0;
    }

    40% {
      opacity: 1;
      width: $width;
      height: $height;
    }

    100% {
      opacity: 1;
      width: $width;
      height: $height;
    }
  }
}
// stylelint-enable plugin/no-low-performance-animation-properties

// Nav link sub
@mixin nav-link-sub {
  width: 100%;
  height: 45px;
  padding-inline-start: var(--pis-nav-link);
  padding-inline-end: var(--pie-nav-link);
  font-size: 16px;
  line-height: 19px;

  &::before,
  &::after {
    font-family: $icomoon-font-family;
    font-size: 10px;
    line-height: 1;
  }

  * {
    color: clr(anthracite);
  }

  @include media-hover(true) {
    &:hover {
      * {
        color: clr(blue);
      }
    }
  }

  &.alt * {
    color: clr(blue);

    &:hover {
      color: clr(anthracite);
    }
  }

  &.nav-link {
    font-weight: 500;

    &::after {
      margin-inline-start: var(--pie-nav-link);
      content: $icon-chevron-right;
    }

    span {
      flex-grow: 1;
    }

    &.nav-link--discount {
      * {
        color: clr(orange);
      }
    }
  }

  &.nav-back {
    margin-block-end: 10px;

    &::before {
      margin-inline-end: 10px;
      content: $icon-chevron-left;
    }
  }
}
