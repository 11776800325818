$icomoon-font-family: "zintuig-icons" !default;
$icomoon-font-path: "fonts" !default;

$icon-basket: "\e900";
$icon-boxes: "\e901";
$icon-chat: "\e902";
$icon-check: "\e903";
$icon-chevron-down: "\e904";
$icon-chevron-left: "\e905";
$icon-chevron-right: "\e906";
$icon-clock: "\e907";
$icon-cross: "\e908";
$icon-duplicate: "\e909";
$icon-envelope: "\e90a";
$icon-filter: "\e90b";
$icon-heart: "\e90c";
$icon-heart-o: "\e90d";
$icon-info: "\e90e";
$icon-quotation: "\e90f";
$icon-leaf: "\e910";
$icon-menu: "\e911";
$icon-not-allowed: "\e912";
$icon-paint-brush: "\e913";
$icon-pencil: "\e914";
$icon-phone: "\e915";
$icon-play: "\e916";
$icon-print: "\e917";
$icon-scale: "\e918";
$icon-search: "\e919";
$icon-share: "\e91a";
$icon-social-facebook: "\e91b";
$icon-social-instagram: "\e91c";
$icon-social-linkedin: "\e91d";
$icon-social-pinterest: "\e91e";
$icon-social-twitter: "\e91f";
$icon-social-whatsapp: "\e920";
$icon-social-youtube: "\e921";
$icon-star: "\e922";
$icon-tag: "\e923";
$icon-trash: "\e924";
$icon-truck: "\e925";
$icon-truck-fast: "\e926";
$icon-truck-fast-alt: "\e927";
$icon-upload: "\e928";
$icon-user: "\e929";
$icon-zoom-in: "\e92a";

