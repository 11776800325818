.section.section--blog-index {
  .container {
    display: flex;
    flex-direction: column;

    > .btn {
      align-self: center;
    }
  }

  .tns-ovh {
    overflow: visible;
  }

  .blog-slider {
    display: flex;

    .card--blog {
      height: 100%;
    }
  }

  .blog-grid {
    display: grid;
    grid-template-columns: 1fr;
    gap: 24px;
  }

  @include media-breakpoint-up(sm) {
    grid-template-columns: 1fr 1fr;
  }

  @include media-breakpoint-up(lg) {
    .blog-grid {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }
}
