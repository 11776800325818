.table-scroller {
  @include overflow(x);

  @include media-breakpoint-down(sm) {
    margin-inline-start: calc(-1 * var(--pi-container));
    margin-inline-end: calc(-1 * var(--pi-container));
  }
}

.table {
  th {
    font-weight: 500;
  }

  thead th {
    white-space: nowrap;
  }

  tbody {
    th {
      font-weight: 400;
    }
  }

  &:not(:first-child) {
    margin-block-start: 15px;
  }

  &.table--striped-odd {
    > tbody > tr:nth-child(odd) {
      background-color: clr(alabaster);
    }
  }

  &.table--striped-even {
    > tbody > tr:nth-child(even) {
      background-color: clr(alabaster);
    }
  }

  @include media-breakpoint-up(lg) {
    &.card {
      overflow: hidden;
      border: 1px solid clr(porcelain);
      border-radius: var(--border-radius);
    }
  }
}