.product-slider {
  .product-slider__track {
    display: flex;
  }

  .card--product {
    height: 100%;
  }

  @include media-breakpoint-down(lg) {
    .tns-ovh {
      overflow: visible;
    }
  }
}

table.sibling-products {
  width: 100%;
  border: 1px solid clr(light-grey);

  tbody tr:nth-of-type(odd) {
    background: clr(porcelain);
  }

  th,
  td {
    padding: 2px 6px;
  }
}

.wizard-finish {
  display: none;
}

.more-tiers {
  cursor: pointer;
}

.imprint-positions {
  .imprint-position {
    padding: 5px;

    img {
      width: 100%;
    }
  }
}

.unpublished {
  filter: blur(5px);
  pointer-events: none;
  user-select: none;
}

.unpublished-message {
  font-size: 1.1rem;
}

.hot-item-text {
  color: clr(carbon);
  border: 1px solid clr(orange);
  padding: .5rem;
  display: block;
  font-size: 1.1rem;
}

.accordion-wrap {
  .accordion__content {
    padding: .8rem;
  }

  .step-actions {
    display: flex;
    justify-content: flex-end;
  }

  .step-select {
    display: flex;
    justify-content: normal;

    .el-select {
      flex-grow: 1;
      margin-right: .5rem;

      .el-select__wrapper {
        height: 50px;
      }
    }
  }
}
