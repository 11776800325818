.tooltip-wrap {
  display: inline-flex;
  margin-inline-start: 5px;
  position: relative;
  z-index: 2;

  .icon-info {
    font-size: 18px;
    color: clr(anthracite);
  }
}

.tooltip {
  width: 250px;
  border-radius: var(--border-radius);
  padding: 15px;
  background-color: clr(anthracite);
  font-weight: 400;
  color: clr(milk);
  white-space: normal;

  .tooltip__arrow,
  .tooltip__arrow::before {
    width: 10px;
    height: 10px;
    background: inherit;
    position: absolute;
  }
    
  .tooltip__arrow {
    visibility: hidden;
  }
    
  .tooltip__arrow::before {
    visibility: visible;
    content: '';
    transform: rotate(45deg);
  }
  
  &[data-popper-placement^='top'] > .tooltip__arrow {
    bottom: -5px;
  }
  
  &[data-popper-placement^='bottom'] > .tooltip__arrow {
    top: -5px;
  }
  
  &[data-popper-placement^='left'] > .tooltip__arrow {
    right: -5px;
  }
  
  &[data-popper-placement^='right'] > .tooltip__arrow {
    left: -5px;
  }

  &:not([data-show]) {
    display: none;
  }
}