.navigation {
  width: 100%;

  .nav-link {
    display: flex;
    align-items: center;

    i {
      margin-inline-end: 13px;
      font-size: 22px;
    }
  }

  .has-sub .sub,
  .has-subsub .subsub {
    @include overflow(y);
    overflow-x: hidden;
    position: absolute;
    transition: transform var(--time-slow) var(--cubic-bezier);
  }

  .has-sub {
    .sub {
      width: 100%;
      background-color: clr(milk);
    }
  }

  .has-subsub {
    > .nav-link {
      @include nav-link-sub;
    }

    .subsub {
      font-size: 14px;
      top: 0;

      .row {
        --bs-gutter-x: 24px;
        --bs-gutter-y: calc(var(--bs-gutter-x) * 1.5);
      }

      .subsub__column-title {
        display: flex;
        align-items: baseline;
        margin-block-end: 15px;
        font-weight: 500;
        line-height: 17px;

        .fa {
          margin-inline-end: 5px;
        }

        span {
          color: clr(blue);
        }

        @include media-hover(true) {
          &:hover {
            span {
              text-decoration: underline;
            }
          }
        }
      }

      ul {
        line-height: 26px;

        a {
          @include media-hover(true) {
            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
    }
  }

  @include media-breakpoint-down(xl) {
    @include overflow(y);
    overflow-x: hidden;
    height: calc(100vh - var(--height-navbar));
    padding-block-start: var(--pb-nav-sub);
    padding-block-end: var(--pb-nav-sub);
    background-color: clr(milk);
    position: absolute;
    top: var(--height-navbar-bottom);
    right: 100%;
    transition: transform var(--time-slow) var(--cubic-bezier);

    .btn-toggle {
      .btn-toggle__icon {
        display: none;
      }
    }

    > ul > li,
    .has-sub {
      > .nav-link {
        @include nav-link-sub;
      }
    }

    .nav-back {
      @include nav-link-sub;
    }

    .has-sub .sub,
    .has-subsub .subsub {
      height: 100%;
      padding-block-start: var(--pb-nav-sub);
      padding-block-end: var(--pb-nav-sub);
      left: 100%;
    }

    .has-sub .sub {
      top: 0;
    }

    .has-subsub .subsub {
      width: 100%;
      background-color: clr(milk);

      .row {
        padding-inline-start: var(--pis-nav-link);
        padding-inline-end: var(--pis-nav-link);
      }
    }

    .has-sub.open .sub,
    .has-subsub.open .subsub {
      transform: translateX(-100%);
    }

    body.nav-open & {
      transform: translateX(100%);
    }
  }

  @include media-breakpoint-up(xl) {
    > ul {
      display: flex;
      justify-content: space-between;

      > li {
        > .nav-link {
          height: var(--height-navbar-bottom);
          padding-inline-start: 20px;
          padding-inline-end: 20px;
          color: clr(milk);
          transition: background-color var(--time-fast) ease-out;

          @include media-hover(true) {
            &.btn-toggle {
              cursor: default;
            }

            &:not(.btn-toggle):not(.nav-link--discount) {
              &:hover {
                background-color: clr(accent-blue);
              }
            }
          }

          &.nav-link--discount {
            background-color: clr(orange);
            font-weight: 500;

            @include media-hover(true) {
              &:hover {
                background-color: clr(accent-orange);
              }
            }
          }
        }

        &:first-child {
          margin-inline-start: -20px;
        }
      }
    }

    .nav-back {
      display: none;
    }

    .has-sub {
      .sub {
        padding-inline-start: var(--pi-container);
        padding-inline-end: var(--pi-container);
        box-shadow: 0 1px 0 clr(porcelain);
        position: fixed;
        position: absolute;
        bottom: calc(100% - var(--height-navbar));
        bottom: 0;
        left: 0;
        z-index: -1;

        .sub__inner {
          height: calc(100vh - var(--height-navbar) - var(--height-navbar-bottom));
          position: relative;

          > ul {
            width: var(--width-sub-ul);
            height: 100%;
            border-inline-end: 1px solid clr(porcelain);
            padding-block-start: 40px;
            padding-block-end: 40px;
          }
        }
      }

      &.open {
        &::before {
          opacity: 1;
        }

        .sub {
          transform: translateY(100%);
        }
      }
    }

    .has-subsub {
      margin-inline-end: -1px;

      > .nav-link {
        border: 1px solid transparent;
      }

      .subsub {
        --overlay: 96px;
        height: 100%;
        padding-block-start: 65px;
        padding-block-end: 65px;

        .subsub__inner {
          width: 200px;
        }

        ul {
          list-style: disc;

          li::marker {
            color: clr(blue);
          }
        }

        @include media-hover(false) {
          width: calc(100% - var(--width-sub-ul));
          padding-inline-start: 113px;
          left: var(--width-sub-ul);
        }

        @include media-hover(true) {
          width: calc(100% - var(--width-sub-ul) + var(--overlay));
          padding-inline-start: calc(var(--overlay) + 113px);
          left: calc(var(--width-sub-ul) - var(--overlay));
        }
      }

      &.open {
        > .nav-link {
          border-color: clr(porcelain);
          border-inline-end-color: clr(milk);

          &::after {
            opacity: 0;
          }
        }

        .subsub {
          z-index: 1;
        }
      }

      &:not(.open) {
        .subsub {
          opacity: 0;
        }
      }
    }
  }

  @include media-breakpoint-up(xxl) {
    .has-sub {
      .sub {
        padding-inline-start: var(--mi-container);
        padding-inline-end: var(--mi-container);
      }
    }
  }
}
