.stars {
  display: inline-block;
  font-size: 40px;
  position: relative;

  > * {
    display: flex;
  }

  .stars__base {
    color: #e7e7e7; // stylelint-disable-line color-no-hex
  }

  .stars__given {
    overflow: hidden;
    color: #ebc878; // stylelint-disable-line color-no-hex
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }
}