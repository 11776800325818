.filter-simple {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 5px;
  margin-block-end: 40px;

  .btn {
    + .btn,
    &:not(:last-of-type) {
      margin: 0;
    }

    &:not(.show) {
      border-color: clr(light-grey);
      color: clr(light-grey);
    }
  }

  @include media-breakpoint-up(sm) {
    grid-template-columns: repeat(4, minmax(0, 1fr));
    gap: 10px;
  }

  @include media-breakpoint-up(md) {
    width: 673px;
    margin-inline-start: auto;
    margin-inline-end: auto;
  }

  @include media-breakpoint-up(lg) {
    margin-block-end: 60px;
  }
}