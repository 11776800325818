.page-footer__bottom {
  border-block-start: 1px solid clr(gallery);
  padding-block-start: 30px;
  padding-block-end: 20px;
  position: relative;

  .social-list {
    @include center-x(top, calc(-.5 * var(--size-btn-io)));
  }

  .container {
    display: flex;
    justify-content: center;

    ul {
      display: flex;
      margin-inline-start: 20px;

      li {
        + li::before {
          content: '  |  ';
          white-space: pre;
        }
      }
    }
  }

  @include media-breakpoint-up(md) {
    padding-block-start: 50px;
  }
}