// Spacing
$spacer: 10px;
$spacers: (
  0: 0,
  5: ($spacer * .5),
  10: $spacer,
  15: ($spacer * 1.5),
  20: ($spacer * 2),
  25: ($spacer * 2.5),
  30: ($spacer * 3),
  35: ($spacer * 3.5),
  40: ($spacer * 4),
  45: ($spacer * 4.5),
  50: ($spacer * 5),
  55: ($spacer * 5.5),
  60: ($spacer * 6),
  65: ($spacer * 6.5),
  70: ($spacer * 7),
  75: ($spacer * 7.5),
  80: ($spacer * 8),
  85: ($spacer * 8.5),
  90: ($spacer * 9),
  95: ($spacer * 9.5),
  100: ($spacer * 10)
);

// Grid breakpoints
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1320px,
);

// Container padding
$container-padding-x: 20px;

// Grid containers
$container-mw-sm: 768px;
$container-mw-md: 1024px;
$container-mw-lg: 1200px;
$container-mw-xl: 1320px;
$container-max-widths: (
  sm: $container-mw-sm + ($container-padding-x * 2),
  md: $container-mw-md + ($container-padding-x * 2),
  lg: $container-mw-lg + ($container-padding-x * 2),
  xl: $container-mw-xl + ($container-padding-x * 2),
);
