.hero.hero--category {
  @include media-breakpoint-up(lg) {
    .row {
      --bs-gutter-x: 50px;

      > *:last-child {
        padding-block-start: 15px;
        padding-block-end: 15px;
      }
    }
  }
}
