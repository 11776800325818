:root {
  // Sizes
  --size-badge-discount: 80px;
  --size-form-check-indicator: 20px;
  --size-counter: 16px;
  --size-btn-io: 40px;
  --size-tns-nav-btn: 12px;

  // Widths
  --width-checkmark: 6px;
  --width-btn-toggle-icon: 20px;
  --width-column-gutter-row: 15px;
  --width-card-service: 310px;

  // Heights
  --height-btn: 50px;
  --height-control: 50px;
  --height-checkmark: 10px;
  --height-navbar-top: 40px;
  --height-navbar-middle: 60px;
  --height-navbar-bottom: 60px;
  --height-navbar: calc(var(--height-navbar-top) + var(--height-navbar-middle) + var(--height-navbar-bottom));
  --height-btn-toggle-icon: 3px;
  --height-main-topbar: 45px;
  --height-column-gutter-row: 15px;
  --height-card-cat-sub: 90px;
    
  // Margins
  --mbs-tns-nav: 35px;
  --mis-form-check-text: 10px;

  // Border radii
  --border-radius: 5px;

  // Paddings
  --pi-btn: 25px;
  --p-control: 15px;
  --pie-select: 20px;
  --pb-nav-sub: 10px;
  --pi-nav-sub: 22px;
  --pis-nav-link: 15px;
  --pie-nav-link: 25px;
  --pb-accordion: 8px;
  --pi-accordion: 20px;
  --pb-section: 40px;

  // Font sizes
  --font-size-base: 14px;
  --font-size-small: 10px;
  --font-size-file-cross: 13px;
  --font-size-select-icon: 9px;

  // Line heights
  --line-height-base: 26px;
  --line-height-small: 18px;
  --line-height-h4: 26px;
  --line-height-h5: 24px;

  // Transitions
  --time-fast: .125s;
  --time-norm: .25s;
  --time-slow: .375s;
  --time-slug: .5s;
  --cubic-bezier: cubic-bezier(.215, .61, .355, 1);

  @include media-breakpoint-up(md) {
    // Sizes
    --size-btn-io: 50px;

    // Heights
    --height-navbar-middle: 90px;
    --height-main-topbar: 60px;

    // Margins

    // Paddings

    // Font sizes
    --font-size-small: 12px;

    // Line heights
    --line-height-small: 20px;
  }

  @include media-breakpoint-up(lg) {
    // Widths
    --width-sub-ul: 335px;

    // Paddings
    --pb-section: 55px;
    --pi-accordion: 15px;
  }
}

// Fonts
$font-path: '../fonts';
$icomoon-font-path: $font-path;
$font-family-base: 'Rubik', sans-serif;