.main-topbar {
  .main-topbar__inner {
    display: flex;
    align-items: center;
    justify-content: center;
    height: var(--height-main-topbar);
  }

  @include media-breakpoint-down(lg) {
    body:not(.home) & {
      border-block-end: 1px solid clr(porcelain);
    }
  }

  @include media-breakpoint-up(lg) {
    .main-topbar__inner {
      justify-content: space-between;
    }

    .tns-ovh {
      height: auto !important;
    }
  }
}

.usps {
  ul {
    display: flex;
    align-items: center;

    li {
      display: flex;
      align-items: center;
      height: var(--height-main-topbar);
      padding-inline-start: 25px;
      color: clr(anthracite);
      position: relative;

      &::before {
        content: $icon-check;
        font-family: $icomoon-font-family;
        font-size: 8px;
        color: clr(orange);
        position: absolute;
        top: calc((var(--height-main-topbar) - var(--font-size-base)) / 3);
        left: 0;
      }
    }
  }

  @include media-breakpoint-down(lg) {
    ul {
      flex-direction: column;
      font-size: 12px;
    }
  }

  @include media-breakpoint-up(lg) {
    ul {
      li {
        margin-inline-end: 50px;

        &::before {
          font-size: 12px;
        }
      }
    }
  }
}