.accordion-wrap {
  padding-block-start: 1px;
  position: relative;

  + .btn {
    margin-block-start: var(--line-height-intro);
  }
}

.accordion {
  text-align: left;

  .accordion__summary {
    display: flex;
    align-items: center;
    cursor: pointer;
    position: relative;

    &::marker,
    &::-webkit-details-marker { // Deprecated but keep for Safari
      display: none;
    }

    &::before,
    &::after {
      flex-shrink: 0;
      font-family: $icomoon-font-family;
      transition: transform var(--time-norm) ease-out;
    }

    &::before {
      color: rgba(clr(dark-grey), .5);
    }

    .accordion__summary__title {
      margin-inline-end: auto;

      + * {
        margin-block-start: 0;
      }
    }

    .accordion__summary__choice {
      margin-inline-start: 30px;
      margin-inline-end: 15px;
      text-align: right;
    }
  }

  &.disabled {
    opacity: .33;
    pointer-events: none;
  }

  &.accordion--step {
    margin-block-start: -1px;
    border-width: 1px 0;
    border-style: solid;
    border-color: clr(porcelain);

    .accordion__summary,
    .accordion__body {
      padding-inline-start: var(--pi-accordion);
      padding-inline-end: var(--pi-accordion);
    }

    .accordion__summary {
      min-height: 50px;
      padding-block-start: var(--pb-accordion);
      padding-block-end: var(--pb-accordion);
      background-color: clr(broken-white);

      &::before {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 35px;
        height: 35px;
        margin-inline-end: 15px;
        border-radius: 50%;
        background-color: clr(porcelain);
        content: $icon-check;
        font-size: 11px;
        line-height: 1;
      }

      &::after {
        content: $icon-chevron-down;
        font-size: 6px;
        color: clr(blue);
      }
    }

    .accordion__body {
      border-block-start: 1px solid clr(porcelain);
      padding-block-start: 15px;
      padding-block-end: 15px;
    }

    .open {
      .accordion__summary {
        &::after {
          transform: scaleY(-1);
        }
      }
    }

    &.done {
      .accordion__summary {
        &::before {
          background-color: clr(blue);
          color: clr(milk);
        }
      }
    }
  }

  &.accordion--faq {
    .accordion__summary,
    .accordion__body {
      padding-inline-start: 20px;
    }

    .accordion__summary {
      &::before {
        @include center-y(left, 0);
        content: $icon-chevron-right;
        font-size: 14px;
        line-height: 1;
        transform-origin: center right;
      }

      .accordion__summary__title {
        color: clr(blue);
      }
    }

    .accordion__body {
      padding-block-start: 10px;
      padding-block-end: 3px;
    }

    + .accordion {
      margin-block-start: 12px;
    }

    &.open {
      .accordion__summary {
        &::before {
          transform: translateY(calc(-50% - 5px)) rotate(-90deg);
        }
      }
    }
  }

  @include media-breakpoint-up(lg) {
    &.accordion--step {
      border-width: 1px;

      .accordion__body {
        padding-block-start: 25px;
        padding-block-end: 25px;
      }
    }
  }
}
