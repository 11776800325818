.tabs {
  &.tabs--default {
    overflow: hidden;
    background-color: clr(milk);
    position: relative;

    .tabs__link {
      height: var(--height-control);
    }

    .tabs__panel {
      ul,
      ol {
        @extend .list;
      }
    }
  }

  &.tabs--simple {
    .tabs__link {
      &.collapse:not(.show) {
        display: inline-flex;
      }
    }

    .tabs__panel {
      @include animation-content;
      display: none;

      &.show {
        display: block;
      }
    }
  }

  @include media-breakpoint-down(sm) {
    &.tabs--default {
      .tabs__panel {
        .btn {
          width: 100%;
        }
      }
    }
  }

  @include media-breakpoint-down(lg) {
    &.tabs--default {
      .tabs__inner {
        margin-inline-start: calc(-1 * var(--pi-container));
        margin-inline-end: calc(-1 * var(--pi-container));
      }

      .tabs__link,
      .tabs__panel {
        padding-inline-start: var(--pi-accordion);
        padding-inline-end: var(--pi-accordion);
      }

      .tabs__link {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        border-block-start: 1px solid clr(porcelain);

        &::after {
          content: $icon-chevron-down;
          font-family: $icomoon-font-family;
          font-size: 6px;
          line-height: 1;
          color: clr(blue);
          transition: transform var(--time-norm) ease-out;
        }

        &:last-of-type {
          border-block-end: 1px solid clr(porcelain);
        }

        &.show {
          &::after {
            transform: scaleY(-1);
          }
        }
      }

      .tabs__panel {
        .tabs__panel__inner {
          padding-block-start: 15px;
          padding-block-end: 30px;
        }
      }
    }
  } 

  @include media-breakpoint-up(lg) {
    .tabs__panel {
      padding-block-start: 30px;
      padding-block-end: 70px;
    }

    &.tabs--default {
      --gutter: 20px;

      @include overflow(x);

      &::after {
        width: 100%;
        height: 1px;
        background-color: clr(porcelain);
        content: '';
        position: absolute;
        top: var(--height-control);
        left: 0;
      }

      .tabs__inner {
        display: flex;
        flex-wrap: wrap;
      }

      .tabs__link {
        display: flex;
        flex-shrink: 0;
        align-items: center;
        justify-content: center;
        border-width: 1px 1px 0;
        border-style: solid;
        border-color: clr(porcelain);
        border-top-left-radius: var(--border-radius);
        border-top-right-radius: var(--border-radius);
        padding-inline-start: var(--gutter);
        padding-inline-end: var(--gutter);
        background-color: clr(broken-white);
        white-space: nowrap;
        position: relative;
        transition: background-color var(--time-norm) ease-out;

        ~ .tabs__link {
          margin-inline-start: -1px;
        }

        &.show {
          background-color: clr(milk);
          z-index: 1;
        }
      }

      .tabs__panel {
        @include animation-content;
        display: none;
        order: 1;
        width: 100%;
  
        &.show {
          display: block;
        }
      }
    }
  }
}
