.table.table--cart {
  thead {
    border-block-end: 1px solid clr(porcelain);
  }

  th,
  td {
    &:not(:first-child) {
      padding-inline-start: 20px;
    }

    &:not(:last-child) {
      padding-inline-end: 20px;
    }
  }

  th {
    color: clr(anthracite);

    .tooltip-wrap {
      vertical-align: middle;
    }
  }

  td {
    .form-control[type='number'] {
      width: 90px;
    }

    &.product,
    &.quantity,
    &.unit-price,
    &.total-price {
      color: clr(anthracite);
    }

    &.img {
      .sku {
        margin-block-end: 15px;
      }

      figure {
        position: relative;

        img {
          @include fill-w-h(absolute);
          object-fit: contain;
        }
      }

      .btn-group {
        margin-block-start: 15px;
      }
    }

    &.product {
      color: clr(anthracite);
    }

    &.imprints {
      .imprint:not(:last-child) {
        margin-block-end: 30px;
      }

      .form-file {
        padding-block-start: 5px;
      }
    }

    &.quantity {
      .input-group + .input-group {
        margin-block-start: 10px;
      }
    }
  }

  > table {
    width: 100%;

    > * {
      > tr {
        > th,
        > td {
          &:first-child {
            padding-inline-start: 20px;
          }

          &:last-child {
            padding-inline-end: 20px;
          }
        }

        > th {
          padding-block-start: 15px;
          padding-block-end: 15px;
        }
      }
    }

    > tbody {
      > tr {
        > td {
          padding-block-start: 25px;
        }
      }
    }

    > tfoot {
      > tr {
        > td {
          padding-block-end: 20px;
        }
      }
    }
  }

  .nested {
    th {
      font-weight: 400;
    }

    td {
      padding-inline-start: 0;
      color: clr(boulder);
    }

    tbody + tbody {
      tr:first-child {
        th,
        td {
          padding-block-start: 15px;
        }
      }
    }
  }

  &.table--cart--view,
  &.table--cart--view-quote {
    thead {
      background-color: clr(broken-white);
    }

    td {
      &.img {
        figure {
          width: 145px;
          height: 145px;
        }
      }
    }
  }

  &.table--cart--checkout {
    td {
      &.img {
        figure {
          width: 75px;
          height: 75px;
        }
      }
    }

    > table {
      > tbody > tr + tr,
      > tfoot {
        border-block-start: 1px solid clr(porcelain);
      }

      > tfoot {
        > tr > td {
          padding-block-start: 35px;
          padding-block-end: 25px;
        }
      }
    }
  }

  @include media-breakpoint-down(xl) {
    thead {
      display: none;
    }

    td {
      &.product {
        .title + * {
          margin-block-start: 0;
        }
      }
    }

    > table,
    > table > tbody,
    > table > tfoot {
      display: block;
    }

    > table {
      > * {
        > tr {
          display: flex;
          flex-direction: column;

          > td {
            .title {
              display: none;
            }

            &[data-th] {
              display: flex;
              align-items: center;
              color: clr(boulder);

              &::before {
                width: 115px;
                padding-inline-end: 40px;
                content: attr(data-th) ':';
                color: clr(anthracite);
              }
            }

            &.total-price {
              padding-block-start: 0;
            }
          }
        }
      }
    }

    .nested th {
      width: 115px;
    }

    &.table--cart--view,
    &.table--cart--view-quote {
      > table > tbody > tr {
        > td {
          &.imprints {
            order: 1;
            padding-block-end: 35px;
          }
        }
      }
    }

    &.table--cart--checkout {
      > table > tbody > tr {
        > td {
          &:last-child {
            padding-block-end: 35px;
          }
        }
      }
    }
  }

  @include media-breakpoint-up(xl) {
    th,
    td {
      &.img,
      &.unit-price,
      &.total-price {
        width: 1px;
        white-space: nowrap;
      }

      &.unit-price,
      &.total-price {
        text-align: right;
      }

      &.img {
        text-align: center;
      }
    }

    > table {
      > tbody {
        > tr {
          > td {
            padding-block-end: 35px;

            &.title-mobile {
              display: none;
            }
          }
        }
      }
    }

    &.table--cart--view,
    &.table--cart--view-quote {
      td {
        &.img {
          figure {
            width: 165px;
            height: 165px;
          }
        }

        &.unit-price,
        &.total-price {
          font-weight: 500;
        }
      }

      > table > tbody > tr {
        border-block-start: 1px solid clr(porcelain);
      }
    }

    &.table--cart--checkout,
    .section--account & {
      th,
      td {
        &.quantity {
          text-align: right;
        }
      }

      td {
        &.img {
          figure {
            width: 90px;
            height: 90px;
          }
        }
      }
    }
  }
}
