.form-check {
  display: block;
  line-height: normal;
  position: relative;

  label {
    display: inline-flex;
    cursor: pointer;

    .card__header & {
      align-items: center;
    }
  }

  .form-check__indicator {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    width: var(--size-form-check-indicator);
    height: var(--size-form-check-indicator);
    border: 1px solid clr(dark-grey);
    background-color: clr(milk);
    position: relative;
    transition: border-color var(--time-norm) ease-out, background-color var(--time-norm) ease-out;

    &::after {
      opacity: 0;
    }
  }

  .form-check__text {
    margin-inline-start: var(--mis-form-check-text);
    user-select: none;
  }

  .collapse__inner {
    padding-block-start: 15px;
    padding-inline-start: calc(var(--size-form-check-indicator) + var(--mis-form-check-text));

    .row {
      --bs-gutter-y: 15px;
    }
  }

  input {
    @include fill-w-h(absolute);
    opacity: 0;
    z-index: -1;

    &:disabled {
      ~ label {
        cursor: default;

        .form-check__indicator {
          border-color: clr(light-grey);
          background-color: clr(broken-white);
        }

        .form-check__text {
          color: clr(light-grey);
        }
      }
    }

    &:checked {
      ~ label .form-check__indicator {
        border-color: clr(blue);

        &::after {
          opacity: 1;
        }
      }
    }

    &[type='checkbox'] {
      ~ label .form-check__indicator {
        border-radius: 2px;

        &::after {
          @include checkmark(var(--width-checkmark), var(--height-checkmark), 2px, clr(blue), 1px);
          position: absolute;
          top: 9px;
          left: 3px;
        }
      }

      &:checked {
        ~ label .form-check__indicator {
          &::after {
            @include checkmark-animation(var(--width-checkmark), var(--height-checkmark));
          }
        }
      }
    }

    &[type='radio'] {
      ~ label .form-check__indicator {
        border-radius: 50%;

        &::after {
          width: 12px;
          height: 12px;
          border-radius: 50%;
          background-color: clr(blue);
          content: '';
          transition: transform var(--time-norm) ease-out, opacity var(--time-fast) ease-out;
          transform: scale(0);
        }
      }

      &:checked {
        ~ label .form-check__indicator {
          &::after {
            transform: scale(1);
          }
        }
      }
    }
  }

  + .form-check {
    margin-block-start: 10px;
  }

  &.payment-method {
    label {
      align-items: center;
    }

    .form-check__text {
      display: flex;
      align-items: center;

      img {
        margin-inline-end: 10px;
      }
    }
  }

  .error ~ label .form-check__indicator {
    border-color: clr(negative);
  }
}
