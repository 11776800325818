.tns-outer,
.product-slider,
.logo-slider,
.review-slider {
  position: relative;
}

.slider-controls {
  .btn {
    width: 55px;
    height: 55px;
    position: absolute;
    top: calc(50% - ((var(--mbs-tns-nav) + var(--size-tns-nav-btn)) / 2));
    transform: translateY(-50%);
        
    i {
      font-size: 14px !important;
      color: clr(dark-grey);
    }

    &:disabled {
      display: none;
    }

    &:first-child {
      left: -27.5px;
    }

    &:last-child {
      right: -27.5px;
    }
  }

  @include media-breakpoint-down(md) {
    display: none;
  }
}

.tns-nav {
  display: flex;
  justify-content: center;
  margin-block-start: var(--mbs-tns-nav);

  button {
    width: var(--size-tns-nav-btn);
    height: var(--size-tns-nav-btn);
    border-radius: 50%;
    background-color: clr(light-grey);
    transition: background-color var(--time-norm) ease-out;

    + button {
      margin-inline-start: 8px;
    }

    &.tns-nav-active {
      background-color: clr(blue);
    }
  }
}

.thumbnail-slider {
  position: relative;

  .thumbnail-slider__track {
    @include overflow(x);
    display: flex;
    scroll-snap-type: x mandatory;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }

    li {
      overflow: hidden;
      flex-shrink: 0;
      width: var(--size-product-thumbnail);
      border: 1px solid clr(porcelain);
      padding: 5px;
      scroll-snap-align: start;

      &.tns-nav-active {
        border-color: clr(blue);
        z-index: 1;
      }
    }
  }

  .thumbnail-slider__controls {
    button {
      display: flex;
      align-items: center;
      z-index: 2;

      &:first-child {
        @include center-y(left, 0);
        display: none;
      }

      &:last-child {
        @include center-y(right, 0);
      }
    }
  }
}