.delivery-option {
  line-height: normal;

  b {
    display: flex;
    align-items: center;
    color: clr(anthracite);

    i {
      margin-inline-end: 10px;
      color: clr(blue);
    }

    + span {
      display: block;
    }

    + * {
      margin-block-start: 5px;
    }
  }

  .delivery-option__text {
    color: clr(dark-grey);
  }
}