:root {
  // Widths
  --width-column-gutter: 14px;

  // Paddings
  --pi-container: #{$container-padding-x};

  @include media-breakpoint-up(sm) {
    // Widths
    --width-container: #{$container-mw-sm};

    // Margins
    --mi-container: calc((100vw - var(--width-container)) / 2);
  }

  @include media-breakpoint-up(md) {
    // Widths
    --width-container: #{$container-mw-md};
    --width-column-gutter: 18px;
  }

  @include media-breakpoint-up(lg) {
    // Widths
    --width-container: #{$container-mw-lg};
  }

  @include media-breakpoint-up(xl) {
    // Widths
    --width-container: #{$container-mw-xl};
    --width-column-gutter: 24px;
  }
}

// Container padding
.container,
.container-fluid {
  padding-inline-start: var(--pi-container);
  padding-inline-end: var(--pi-container);
}

// Grid columns
.row {
  &:not([class*='g-']) {
    --bs-gutter-x: var(--width-column-gutter);
  }

  &.row--btns {
    .btn {
      width: 100%;
    }
  }
}
