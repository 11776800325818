.dropzone {
  border: 1px dashed clr(boulder);
  line-height: normal;

  .dropzone-previews {
    margin: -16px;
  }

  .dz-preview {
    .dz-image img {
      @include fill-w-h(absolute);
      object-fit: cover;
    }

    .dz-success-mark,
    .dz-error-mark {
      top: 60px;
    }
  }

  .dz-message {
    margin: 0;
    padding: 5% 0;
  }

  .dz-remove {
    margin-block-start: 5px;
    color: clr(negative);
  }

  &:not(.dz-clickable) {
    border: 0;
    padding: 0;
  }
}