.page-footer__top {
  border-block-start: 1px solid clr(gallery);
  background-color: clr(broken-white);
    
  .page-footer__top__inner {
    padding-block-start: 30px;
    padding-block-end: 40px;
  }
        
  .contact-item {
    display: inline-flex;
    align-items: center;

    .btn--io {
      width: 70px;
      height: 70px;
      margin-inline-end: 10px;
      color: clr(orange);

      i {
        font-size: 22px;
      }
    }
        
    .contact-item__body {
      display: flex;
      flex-direction: column;
    }
        
    .contact-item__title {
      + span {
        margin-block-start: 0;
      }
    }

    @include media-hover(true) {
      &:not(div):hover {
        .btn--io {
          background-color: clr(orange);
          color: clr(milk);
        }
      }
    }
  }

  @include media-breakpoint-down(lg) {
    .page-footer__top__inner {
      .row {
        --bs-gutter-y: 20px;
      }
    }
  }

  @include media-breakpoint-up(lg) {
    .page-footer__top__inner {
      padding-block-start: 40px;
      padding-block-end: 30px;
    }
  }
}