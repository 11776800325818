.badge {
  color: clr(milk);

  &.badge--discount {
    width: var(--size-badge-discount);
    height: var(--size-badge-discount);
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    position: relative;
        
    .badge--discount__bg {
      @include fill-w-h(absolute);
      border-inline-start: var(--size-badge-discount) solid transparent;
    }

    .badge--discount__text {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 52px;
      height: 52px;
      position: absolute;
      top: 0;
      right: 0;
      transform: matrix(.71, .71, -.71, .71, 0, 0);
    }
  }

  &.badge--leaf {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background-color: clr(positive);

    &::before {
      content: $icon-leaf;
      font-family: $icomoon-font-family;
      font-size: 40px;
      line-height: 1;
    }
  }
}