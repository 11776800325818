@mixin form-control {
  width: 100%;
  border: 1px solid clr(light-grey);
  border-radius: var(--border-radius);
  padding-inline-start: var(--p-control);
  padding-inline-end: var(--p-control);
  background-color: clr(milk);
  color: clr(anthracite);
  text-align: left;

  &::placeholder {
    color: clr(boulder);
  }

  &:disabled {
    opacity: .3;
  }

  &:not(textarea) {
    display: flex;
    align-items: center;
    height: var(--height-control);
  }

  // &[type='number'] {
  //     text-align: center;
  // }

  &[required] {
    &::after {
      content: '*';
    }
  }

  &.error {
    border-color: clr(negative);
  }
}

.form-control {
  @include form-control;
}

textarea.form-control {
  display: block;
  height: auto;
  min-height: calc((var(--height-control) * 3) + (var(--height-column-gutter-row) * 2) - var(--line-height-h4) - 15px);
  padding-block-start: var(--p-control);
  padding-block-end: var(--p-control);
  resize: vertical;
}

.form-control.is-invalid,
.was-validated .form-control:invalid {
  border-color: clr(negative);
}
