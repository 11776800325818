.input-group {
  display: flex;
  align-items: center;
  position: relative;

  .invalid-feedback {
    display: none !important;
  }

  &:not(.input-group--absolute) {
    .form-control {
      margin-inline-end: calc(-1 * var(--border-radius));
      border-inline-end: 0;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  &.input-group--absolute {
    .input-group__text {
      position: absolute;
      top: 13px;
      left: var(--p-control);
    }

    .form-control {
      padding-inline-start: calc(var(--p-control) * 2);
    }
  }

  @include media-breakpoint-down(sm) {
    .btn:not(.btn--square) {
      width: auto;
    }
  }
}