.list-icon {
  li {
    a,
    span {
      display: inline-flex;
      align-items: center;
      line-height: 1;

      i {
        margin-inline-end: 10px;

        &.icon-phone {
          font-size: 12.5px;
        }
        
        &.icon-envelope {
          font-size: 10px;
        }
      }
    }

    a {
      color: clr(blue);

      i {
        color: clr(orange);
      }

      @include media-hover(true) {
        &:hover {
          color: clr(accent-blue);
        }
      }
    }

    + li {
      margin-block-start: 10px;
    }
  }

  .navbar__contact & {
    display: flex;

    li {
      a,
      span {
        i {
          margin-inline-end: 5px;
        }
      }

      + li {
        margin-block-start: 0;
        margin-inline-start: 15px;
      }
    }

    @include media-breakpoint-down(md) {
      li:last-child {
        display: none;
      }
    }
  }

  .card--service & {
    margin-block-start: 15px !important;

    @include media-breakpoint-up(lg) {
      margin-block-start: 25px !important;
    }
  }
}