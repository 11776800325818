.section.section--cart {
  .section__intro {
    text-align: left;
  }

  .section__discount {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    .btn {
      margin-block-start: 0;
    }

    .input-group {
      margin-block-end: 30px;
    }
  }

  @include media-breakpoint-up(sm) {
    .section__intro {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .btn {
        margin-block-start: 0;
      }
    }
  }
}
