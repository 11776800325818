.choice {
  display: block;
  cursor: pointer;

  input {
    @include fill-w-h(absolute);
    opacity: 0;
    z-index: -1;

    + * {
      border-color: clr(blue);
      position: relative;
    }

    &:checked {
      + * {
        z-index: 1;
      }
    }
  }

  &.choice--text {
    .choice__body {
      display: flex;
      align-items: center;
      min-width: 75px;
      height: 40px;
      border: 1px solid clr(porcelain);
      padding-inline-start: 15px;
      padding-inline-end: 15px;
    }

    input {
      &:checked {
        + .choice__body {
          border-color: clr(blue);
        }
      }
    }

    &:not(.choice--text--big) {
      .choice__body {
        justify-content: center;
      }
    }

    &.choice--text--big {
      .choice__body {
        height: 50px;
        padding-inline-start: 15px;
        padding-inline-end: 15px;
      }

      + .choice--text--big {
        margin-block-start: -1px;
      }
    }
  }

  &.choice--img {
    flex-shrink: 0;

    div {
      overflow: hidden;
      border: 1px solid clr(porcelain);
      width: 100%;
      padding: 5px;

      figure {
        padding-block-start: 100%;
        position: relative;

        .cake-error {
          position: absolute;
          top: 0;
        }

        img {
          @include fill-w-h(absolute);
          object-fit: contain;
        }
      }
    }

    span,
    small {
      display: block;
    }

    input {
      &:checked {
        + div {
          border-color: clr(blue);
        }
      }
    }

    &.choice--img--without {
      i {
        @include center-x-y(absolute);
        font-size: 80px;
        color: clr(porcelain);
      }
    }

    @include media-breakpoint-down(sm) {
      &.choice--img--color {
        &:not(:nth-child(4n+1)) {
          margin-inline-start: -1px;
        }

        &:nth-child(n+5) {
          margin-block-start: 10px;
        }
      }
    }

    @include media-breakpoint-up(sm) {
      &.choice--img--color {
        &:not(:nth-child(4n+1)) {
          margin-inline-start: -1px;
        }

        &:nth-child(n+4) {
          margin-block-start: 10px;
        }
      }
    }
  }
}

.color-wrap {
  display: flex;
  flex-wrap: wrap;
}
