.btn-toggle {
  display: flex;
  align-items: center;
  z-index: 100;

  .btn-toggle__icon {
    display: flex;
    flex-direction: column;
    margin-inline-end: 10px;

    .btn-toggle__icon__row {
      display: flex;

      + * {
        margin-block-start: 6px;
      }
    }

    .btn-toggle__icon__dot,
    .btn-toggle__icon__line {
      height: var(--height-btn-toggle-icon);
      background-color: currentcolor;
      transition: opacity var(--time-norm) ease-out, transform var(--time-norm) ease-out;
    }

    .btn-toggle__icon__dot {
      width: var(--height-btn-toggle-icon);
      margin-inline-end: var(--height-btn-toggle-icon);
      // border-radius: 50%;
    }

    .btn-toggle__icon__line {
      width: var(--width-btn-toggle-icon);
    }
  }

  .btn-toggle__text {
    font-weight: 500;
    font-size: 16px;
    white-space: nowrap;
  }

  @include media-hover(false) {
    &.open {
      .btn-toggle__icon {
        .btn-toggle__icon__dot,
        .btn-toggle__icon__row:nth-child(2) .btn-toggle__icon__line {
          opacity: 0;
        }

        .btn-toggle__icon__row {
          &:nth-child(1) {
            .btn-toggle__icon__line {
              transform: translateY(9px) rotate(45deg);
            }
          }

          &:nth-child(3) {
            .btn-toggle__icon__line {
              transform: translateY(-9px) rotate(-45deg);
            }
          }
        }
      }
    }
  }
}