.hero.hero--home {
  .hero--home__cat {
    &.hero--home__cat--wide {
      .hero__img {
        padding-block-start: 61.07784431%;
      }
    }
        
    &.hero--home__cat--square {
      display: flex;
      padding-block-start: 20px;
      padding-block-end: 20px;
            
      .hero__img {
        flex-shrink: 0;
        width: 75px;
        height: 75px;
        margin-inline-end: 25px;
      }
    }
  }

  @include media-breakpoint-down(lg) {
    .hero--home__cat {
      &.hero--home__cat--wide {
        .hero__img {
          margin-block-end: 27.5px;
        }
      }

      &.hero--home__cat--square {
        margin-inline-start: calc(-1 * var(--pi-container));
        margin-inline-end: calc(-1 * var(--pi-container));
        border-block-start: 1px solid clr(porcelain);
        padding-inline-start: var(--pi-container);
        padding-inline-end: var(--pi-container);
      }
    }
  }

  @include media-breakpoint-up(lg) {
    .hero--home__cat {
      &.hero--home__cat--wide {
        height: 100%;
        position: relative;

        .hero__img {
          height: 100%;
          padding-block-start: 57.33944954%;
        }

        .hero--home__cat__body {
          width: 460px;
          padding: 60px 50px;
          position: absolute;
          top: 0;
          left: 0;
          z-index: 1;
        }
      }

      &.hero--home__cat--square {
        // min-height: calc(100% / 3);
        border-block-end: 1px solid clr(porcelain);
        padding-block-start: 25px;
        padding-block-end: 25px;

        .hero__img {
          width: 120px;
          height: 120px;
        }
      }
    }
  }
}