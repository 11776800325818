.section.section--blog-view {
  img {
    &[align='left'] {
      margin-inline-end: 15px;
    }

    &[align='right'] {
      margin-inline-start: 15px;
    }
  }

  .hero .hero__img,
  .section--blog-view__intro {
    margin-block-start: 15px;
  }

  .section--blog-view__intro {
    font-weight: 500;

    p:empty,
    br {
      display: none;
    }
  }

  .section--blog-view__content {
    img {
      max-width: 100%;
    }
  }
}