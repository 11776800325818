.section.section--account {
  .section--account__content {
    padding-block-end: 50px;

    b,
    strong {
      color: clr(anthracite);
    }
  }

  .section__intro {
    text-align: left;
  }

  .section--account__block {
    + .section--account__block {
      margin-block-start: 60px;
    }
  }

  .section--account__actions {
    display: flex;

    .link {
      + .link {
        margin-inline-start: 10px;
      }
    }
  }

  @include media-breakpoint-up(lg) {
    .section--account__content {
      padding-block-end: 140px;
      padding-inline-start: calc(70px - var(--bs-gutter-x));
      padding-inline-end: 70px;
    }
  }
}