.btn {
  overflow: hidden;
  display: inline-flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  border: 1px solid transparent;
  font-weight: 500;
  white-space: nowrap;
  position: relative;

  i {
    font-size: 16px;

    &[class*='arrow'],
    &[class*='chevron'] {
      font-size: 7px;
    }
  }

  span {
    &::first-letter {
      text-transform: uppercase;
    }
  }

  &:disabled {
    pointer-events: none;
    user-select: none;

    &.go &::after {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: rgba(128, 128, 128, 50%); // grijze overlay
    }
  }

  @include media-hover(true) {
    transition: all var(--time-fast) ease-out;

    * {
      z-index: 1;
    }
  }

  &:not(.btn--io) {
    height: var(--height-btn);
    border-radius: var(--border-radius);
    padding-inline-start: var(--pi-btn);
    padding-inline-end: var(--pi-btn);
    font-size: 16px;
    line-height: 19px;

    &:not(:last-of-type) {
      margin-inline-end: 10px;
    }

    &.btn--primary,
    &.btn--secondary {
      color: clr(milk);
    }

    &.btn--primary {
      border-color: clr(blue);
      background-color: clr(blue);

      @include media-hover(true) {
        &:not(label) {
          &::before {
            @include fill-w-h(absolute);
            background: transparent linear-gradient(180deg, clr(accent-blue) 0%, clr(blue) 100%) 0% 0% no-repeat padding-box;
            content: '';
            transition: opacity var(--time-fast) ease-out;
          }

          &:not(:hover) {
            &::before {
              opacity: 0;
            }
          }
        }
      }
    }

    &.btn--secondary {
      border-color: clr(orange);
      background-color: clr(orange);

      @include media-hover(true) {
        &:hover {
          background-color: clr(accent-orange);
        }
      }
    }

    &.btn--light {
      border-color: clr(blue);
      color: clr(blue);

      @include media-hover(true) {
        &:hover {
          background-color: clr(broken-white);
        }
      }
    }

    &.btn--square {
      width: var(--height-control);
      padding-inline-start: 0;
      padding-inline-end: 0;
    }

    &.btn--il {
      i {
        margin-inline-end: 10px;
      }
    }

    &.btn--ir {
      i {
        margin-inline-start: 10px;
      }
    }

    @include media-breakpoint-down(sm) {
      width: 100%;

      + .btn {
        margin-block-start: 15px;
      }
    }
  }

  &.btn--io {
    width: var(--size-btn-io);
    height: var(--size-btn-io);
    border-color: clr(gallery);
    border-radius: 50%;
    background-color: clr(milk);
    color: clr(blue);

    + .btn--io {
      margin-inline-start: 5px;
    }

    @include media-hover(true) {
      &:not(div):hover {
        background-color: clr(blue);
        color: clr(milk);

        i {
          color: clr(milk);
        }
      }
    }

    @include media-breakpoint-up(md) {
      i {
        font-size: 20px;
      }
    }
  }
}

.btn-group {
  display: inline-flex;

  .btn {
    width: var(--height-control);
    padding-inline-start: 0;
    padding-inline-end: 0;

    + .btn {
      margin-block-start: 0;
    }

    &:not(:last-of-type) {
      margin-inline-end: -1px;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    &:not(:first-of-type) {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
}
