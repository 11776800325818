.card.card--review {
  display: block;

  .card__body {
    height: 100%;
    padding: 30px 20px;
  }

  .card--review__top {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .card--review__bottom {
    margin-block-start: 20px;
    padding-block-start: 20px;
    position: relative;

    &::before {
      @include center-x(top, 0);
      width: 150px;
      height: 1px;
      background-color: clr(porcelain);
      content: '';
    }
  }

  .stars {
    margin-block-end: 5px;
  }

  .card--review__rating {
    color: clr(blue);
  }
    
  .card--review__name {
    color: clr(anthracite);
  }

  @include media-breakpoint-up(lg) {
    .card__body {
      padding: 35px 25px;
    }
  }
}