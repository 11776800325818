.hero {
  padding-block-end: var(--pb-section);

  .hero__img {
    overflow: hidden;
    position: relative;

    img {
      @include fill-w-h(absolute);
      object-fit: cover;
    }

    &.hero__img--big {
      padding-block-start: 48.61111111%;
    }
  }

  @include media-breakpoint-down(lg) {
    &:not(.hero--home) {
      padding-block-start: calc(var(--pb-section) / 2);
    }
  }
}