.products-grid {
  display: grid;
  gap: 20px;

  @include media-breakpoint-up(sm) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  @include media-breakpoint-up(md) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  @include media-breakpoint-up(lg) {
    .section:not(.section--account) & {
      grid-template-columns: repeat(4, minmax(0, 1fr));
      gap: 25px;
    }
  }
}

.card.card--product {
  --size-product-thumbnail: 40px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: clr(milk);

  > * {
    &:first-child {
      padding: 10px 10px 20px;
    }

    &:last-child {
      padding-block-end: 25px;
      padding-inline-start: 25px;
      padding-inline-end: 25px;
    }
  }

  figure {
    padding-block-start: 100%;

    img {
      object-fit: contain;
    }
  }

  .badge,
  .btn {
    position: absolute;
    z-index: 1;
  }

  .badge {
    top: 0;
    right: 0;
  }

  .btn {
    top: 20px;
    left: 20px;
  }

  .card--product__title {
    overflow: hidden;
    display: -webkit-box; // stylelint-disable-line value-no-vendor-prefix
    height: calc(var(--line-height-h5) * 2);
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical; // stylelint-disable-line property-no-vendor-prefix
  }

  .thumbnail-slider {
    margin-inline-start: 25px;
    margin-inline-end: 25px;
    padding-inline-start: 17px;
    padding-inline-end: 17px;

    .thumbnail-slider__track {
      li {
        + li {
          margin-inline-start: 5px;
        }
      }
    }

    .thumbnail-slider__controls {
      button {
        height: 100%;
        font-size: 14px;
        color: clr(dusty);
      }
    }
  }

  .card--product__body {
    padding-block-start: 20px;

    ul {
      margin-block-start: 15px;
    }
  }

  .price {
    align-items: flex-end;
    margin-block-start: 10px;
  }
}

.section--content ul {
  list-style: disc !important;
  margin-left: 15px;
}

.imprint-option {
  figure,
  img {
    max-width: 100%;
  }
}
