.card.card--catsub {
  display: flex;
  align-items: center;
  height: var(--height-card-cat-sub);
  border-radius: 0;
  padding: 10px 15px 10px 10px;

  i,
  figure {
    width: 70px;
    margin-inline-end: 15px;
  }

  i {
    display: flex;
    justify-content: center;
    font-size: 55px;
    color: clr(blue);
  }

  figure {
    flex-shrink: 0;
    height: 70px;

    img {
      object-fit: contain;
    }
  }

  .card--catsub__title {
    hyphens: auto;
  }

  @include media-breakpoint-down(lg) {
    margin-block-start: -1px;
  }
}

.catsub-wrap {
  margin-block-start: 30px;

  .catsub-wrap__inner {
    display: flex;
  }

  + .catsub-wrap {
    margin-block-start: calc(var(--height-card-cat-sub) / 2);
  }

  &:not(.catsub-wrap--not-all) {
    .toggle-more-cats {
      span:last-child {
        display: none;
      }
    }
  }

  &.catsub-wrap--not-all {
    .toggle-more-cats {
      span:first-child {
        display: none;
      }
    }
  }

  @include media-breakpoint-down(xxl) {
    &.catsub-wrap--not-all {
      .card {
        &:nth-child(n+4) {
          display: none;
        }
      }
    }
  }

  @include media-breakpoint-down(lg) {
    .catsub-wrap__inner {
      flex-direction: column;
    }
        
    .toggle-more-cats {
      align-self: center;
      margin-block-start: 30px;
    }
  }

  @include media-breakpoint-up(lg) {
    margin-block-start: 25px;

    .catsub-wrap__inner {
      flex-wrap: wrap;
      margin: -6px;

      > * {
        width: 25%;
        padding: 6px;
      }
    }

    .toggle-more-cats {
      &.btn {
        height: 90px;

        span {
          display: flex;
          align-items: center;
        }
      }
    }

    &.catsub-wrap--not-all {
      .toggle-more-cats {
        &.btn--il {
          i {
            margin-inline-start: 10px;
            margin-inline-end: 0;
          }
        }
      }
    }

    .section--catsubs & {
      .catsub-wrap__inner {
        justify-content: center;
      }
    }
  }

  @include media-breakpoint-up(xxl) {
    .catsub-wrap__inner {
      > * {
        width: 20%;
      }
    }

    &.catsub-wrap--not-all {
      .card {
        &:nth-child(n+5) {
          display: none;
        }
      }
    }
  }
}