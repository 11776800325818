.back-link {
  display: flex;
  align-items: center;
  margin-block-end: 10px;
  font-size: 12px;

  i {
    margin-inline-end: 7px;
    font-size: 7px;
  }
}