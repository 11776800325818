.collapse:not(.show) {
  display: none;
}

.collapsing {
  overflow: hidden;
  height: 0;
  // stylelint-disable-next-line plugin/no-low-performance-animation-properties
  transition: height var(--time-norm) ease;
}

@keyframes rotate {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes show-underlay {
  0% { opacity: 0; }
  100% { opacity: .15; }
}

@keyframes hide-underlay {
  0% { opacity: .15; }
  100% { opacity: 0; }
}

@keyframes scale-drop-down-typeahead {
  0% {
    transform: scale(1, .7);
  }

  100% {
    transform: scale(1, 1);
  }
}
