.card.card--service {
  min-width: var(--width-card-service);
  padding: 25px 20px 10px;

  > *:not(picture) {
    position: relative;
    z-index: 1;
  }

  img {
    height: 125px;
    position: absolute;
    bottom: 0;
    right: 0;
  }

  @include media-breakpoint-up(lg) {
    padding-block-start: 25px;
    padding-block-end: 10px;

    img {
      height: 150px;
    }
  }
}
