.card.card--blog {
  text-align: left;

  > a {
    display: flex;
    flex-direction: column;
  }

  figure {
    flex-shrink: 0;
    padding-block-start: 62.16216216%;

    img {
      object-fit: cover;
    }
  }

  .card--blog__body {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: 30px 20px;

    .card--blog__title {
      flex-grow: 1;
    }

    .link {
      display: inline-block;
    }
  }

  @include media-breakpoint-up(lg) {
    figure {
      padding-block-start: 66.74528302%;
    }

    .card--blog__body {
      padding: 35px 25px;
    }
  }
}