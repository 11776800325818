.section.section--cats {
  .section--cats__cats {
    display: flex;
    flex-wrap: wrap;

    .card {
      width: 50%;
    }
  }

  @include media-breakpoint-down(md) {
    .section--cats__cats {
      .card {
        &:nth-child(even) {
          margin-inline-start: -1px;
        }

        &:nth-child(n+3) {
          margin-block-start: -1px;
        }
      }
    }
  }

  @include media-breakpoint-only(md) {
    .section--cats__cats {
      .card {
        width: calc(100% / 3);

        &:not(:nth-child(3n+1)) {
          margin-inline-start: -1px;
        }

        &:nth-child(n+4) {
          margin-block-start: -1px;
        }
      }
    }
  }

  @include media-breakpoint-up(lg) {
    .section--cats__cats {
      .card {
        width: calc(100% / 4);

        &:not(:nth-child(4n+1)) {
          margin-inline-start: -1px;
        }

        &:nth-child(n+5) {
          margin-block-start: -1px;
        }
      }
    }
  }
}