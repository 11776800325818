.price-wrap {
  .price-wrap__inner {
    padding-block-end: 20px;

    .row {
      --bs-gutter-y: 10px;
      align-items: center;
    }
  }
}

.price {
  display: flex;
  flex-direction: column;
  font-weight: 500;

  .price__old {
    color: clr(anthracite);
    text-decoration: line-through;
  }

  .price__current {
    display: flex;
    align-items: flex-end;
    color: clr(blue);

    .price__current__price {
      margin-inline-start: 5px;
      color: clr(blue);
    }

    &.text-orange {
      .price__current__price {
        color: clr(orange);
      }
    }
  }
}

small.old-price {
  color: clr(anthracite);
  text-decoration: line-through;
}
