.form-file {
  .form-file__inner {
    position: relative;
  }

  input {
    @include fill-w-h(absolute);
    opacity: 0;
    z-index: -1;
  }
    
  .btn {
    overflow: hidden;
    width: 100%;
    padding-inline-end: var(--height-control);
    cursor: pointer;
    position: relative;

    &::before,
    .form-file__text {
      transition: transform var(--time-slug) var(--cubic-bezier);
    }

    &::before {
      flex-shrink: 0;
      margin-inline-end: 10px;
      content: $icon-upload;
      font-family: $icomoon-font-family;
      font-size: 19px;
      line-height: 1;
    }

    .form-file__text {
      overflow: hidden;
      flex-grow: 1;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .form-file__btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: var(--height-control);
    height: var(--height-control);
    color: clr(milk);
    position: absolute;
    top: 0;
    right: 0;

    &::before {
      content: $icon-cross;
      font-family: $icomoon-font-family;
      font-size: var(--font-size-file-cross);
      line-height: 1;
      transition: transform var(--time-norm) ease-out;
    }

    @include media-hover(true) {
      transition: color var(--time-norm) ease-out;

      &:hover {
        color: clr(negative);
      }
    }
  }

  &:not(.active) {
    .btn {
      &::before {
        transform: translateX(calc(-1 * (var(--pi-btn) + 1px + 100%)));
      }

      .form-file__text {
        transform: translateX(-32px);
      }
    }

    .form-file__btn {
      pointer-events: none;

      &::before {
        transform: rotate(-225deg);
      }
    }
  }

  @include media-breakpoint-up(sm) {
    width: 250px;
  }
}