body {
  font-family: $font-family-base;
  font-weight: 400;
  font-size: var(--font-size-base);
  line-height: var(--line-height-base);
  color: clr(dark-grey);
}

body,
button,
input,
select,
textarea {
  text-rendering: optimizelegibility;
  -webkit-tap-highlight-color: transparent;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-feature-settings: 'liga', 'kern';
}

// Headings
@mixin h-default {
  font-weight: 500;
  color: clr(anthracite);

  &:not(th):not(td) {
    + *:not(time),
    + .row {
      margin-block-start: 15px;
    }
  }
}

@mixin h1 {
  @include h-default;
  font-size: 24px;
  line-height: 30px;

  @include media-breakpoint-up(md) {
    font-size: 28px;
    line-height: 48px;
  }

  @include media-breakpoint-up(xl) {
    font-size: 32px;
    line-height: 50px;
  }
}

@mixin h2 {
  @include h-default;
  font-size: 20px;
  line-height: 32px;

  @include media-breakpoint-up(md) {
    font-size: 24px;
    line-height: 38px;
  }

  @include media-breakpoint-up(xl) {
    font-size: 26px;
    line-height: 40px;
  }
}

@mixin h3 {
  @include h-default;
  font-size: 16px;
  line-height: 26px;

  @include media-breakpoint-up(md) {
    font-size: 20px;
  }

  @include media-breakpoint-up(xl) {
    font-size: 24px;
    line-height: 32px;
  }
}

@mixin h4 {
  @include h-default;
  font-size: 20px;
  line-height: var(--line-height-h4);
}

@mixin h5 {
  @include h-default;
  font-size: 18px;
  line-height: var(--line-height-h5);
}

@mixin h6 {
  @include h-default;
  font-size: 16px;
  line-height: 22px;
}

@mixin small {
  font-size: var(--font-size-small);
  line-height: var(--line-height-small);
}

h1,
.h1 {
  @include h1;
}

h2,
.h2 {
  @include h2;
}

h3,
.h3 {
  @include h3;
}

h4,
.h4 {
  @include h4;
}

h5,
.h5 {
  @include h5;
}

h6,
.h6 {
  @include h6;
}