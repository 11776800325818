.flatpickr-day {
  &.selected,
  &.startRange,
  &.endRange,
  &.selected.inRange,
  &.startRange.inRange,
  &.endRange.inRange,
  &.selected:focus,
  &.startRange:focus,
  &.endRange:focus,
  &.selected:hover,
  &.startRange:hover,
  &.endRange:hover,
  &.selected.prevMonthDay,
  &.startRange.prevMonthDay,
  &.endRange.prevMonthDay,
  &.selected.nextMonthDay,
  &.startRange.nextMonthDay,
  &.endRange.nextMonthDay {
    border-color: clr(blue);
    background: clr(blue);
  }
}

.flatpickr-months {
  .flatpickr-prev-month,
  .flatpickr-next-month {
    &:hover svg {
      fill: clr(orange);
    }
  }
}
