.section.section--checkout {
  .section__intro {
    text-align: left;
  }

  @include media-breakpoint-up(sm) {
    .section__intro {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  @include media-breakpoint-up(lg) {
    .card {
      + .card {
        margin-block-start: 30px;
      }
    }
  }
}