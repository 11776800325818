.table.table--specs {
  th,
  td {
    padding: 5px 10px;
    vertical-align: middle;

    &:first-child {
      padding-inline-start: 0;
    }

    &:last-child {
      padding-inline-end: 0;
    }
  }
}