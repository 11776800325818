.modal {
  @include fill-w-h(fixed);
  z-index: 101;
  transition: visibility var(--time-fast) ease-out, background-color var(--time-fast) ease-out;

  .modal__dialog {
    @include overflow(y);
    display: flex;
    flex-direction: column;
    width: calc(100% - 30px);
    max-height: calc(100% - 30px);
    border-radius: var(--border-radius);
    padding: 15px 15px 25px;
    box-shadow: 0 2px 29px 0 rgba(clr(carbon), .08);
    background-color: clr(milk);
    position: fixed;
    top: 100%;
    left: 50%;
    z-index: 1;
    transition: transform var(--time-slow) var(--cubic-bezier);
    transform: translateX(-50%);
  }

  .modal__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    .icon-check,
    .icon-cross {
      margin-block-end: 10px;
      font-size: 24px;
    }

    .icon-check {
      color: clr(positive);
    }

    .icon-cross {
      color: clr(negative);
    }

    figure {
      width: 160px;
      height: 147px;
      margin-block-start: 25px;
      margin-block-end: 15px;
      position: relative;

      img {
        @include fill-w-h(absolute);
        object-fit: contain;
      }
    }

    .link {
      margin-block-end: 20px;
    }
  }

  &:not(.show) {
    visibility: hidden;
    transition-delay: var(--time-norm);
  }

  &.show {
    background-color: rgba(clr(carbon), .5);

    .modal__dialog {
      transform: translate(-50%, calc(-50% - 50vh));
    }
  }

  &.modal--added-to {
    .modal__dialog {
      border: 2px solid clr(positive);
    }

    .cart,
    .quotation,
    .compare {
      display: none;
    }

    &.modal--added-to--cart {
      .cart {
        display: inline-flex;
      }
    }

    &.modal--added-to--quotation {
      .quotation {
        display: inline-flex;
      }
    }

    &.modal--added-to--compare {
      .compare {
        display: inline-flex;
      }
    }
  }

  @include media-breakpoint-up(md) {
    .modal__dialog {
      width: 650px;
    }

    .modal__content {
      figure {
        width: 244px;
        height: 227px;
      }
    }
  }
}
