.pagination {
  display: flex;
  justify-content: center;
  margin-block-start: 45px;

  li {
    a,
    button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 30px;
      height: 30px;
      border: 1px solid clr(light-grey);
      border-radius: var(--border-radius);
      font-size: 14px;
      line-height: 21px;
      color: clr(anthracite);

      i {
        font-size: 7px;
        color: clr(blue);
      }
    }

    + li {
      margin-inline-start: 5px;
    }

    &.disabled:not(.active) {
      a,
      button {
        opacity: .5;
      }
    }

    &.active {
      a,
      button {
        border-color: clr(blue);
        background-color: clr(blue);
        color: clr(milk);
      }
    }

    &:not(.disabled):not(.active) {
      @include media-hover(true) {
        a,
        button {
          &:hover {
            border-color: clr(blue);
          }
        }
      }
    }
  }
}